import { useSelector, useDispatch } from "react-redux";
import { addAllUser } from "../../redux/slices/userSlice";
import {allUsers, blockUnblockUser, deleteUser, getActivities, signUp} from "../../api/api";
import {
    Box, Typography, TextField, Modal, InputLabel, MenuItem, FormControl,
    TableRow, TableHead, TableContainer, Table, TableBody, TableCell,
    TablePagination, Select, Paper, Button, ButtonGroup
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useMakeToast from "../../hooks/makeToast";
import Loading from "../../components/Loading";
import person from "../../assets/svg/person.svg";
import { io } from "socket.io-client";
import CSVUploadModal from "./CSVUploadModal";
import {ToastContainer} from "react-toastify";
import AlertDialog from "../../components/AlertDialog";
import FilterDropDown from "../../components/filterDropDown";
import {useLocation, useParams} from "react-router-dom";
import {tableText} from "../../components/miniComponents";
import TableSkeleton from "../../components/TableSkeleton";
// export const socket = io.connect(URL, { transports: ["websocket"] });

const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "1px solid #888",
    boxShadow: 24,
    borderRadius: "8px",
    p: 4,
};

const UserPage = () => {
    const userList = useSelector((state) => state?.users?.allUser);
    const [loading, setLoading] = useState(true);
    const [allUserList, setAllUserList] = useState([]);
    const [countries , setCountries] = useState([]);
     const [countriesData, setCountriesData] = useState([
       { icon: "🇦🇫", name: "Afghanistan", abbr: "AF", code: "93" },
       { icon: "🇦🇽", name: "Alland Islands", abbr: "AX", code: "358" },
       { icon: "🇦🇱", name: "Albania", abbr: "AL", code: "355" },
       { icon: "🇩🇿", name: "Algeria", abbr: "DZ", code: "213" },
       { icon: "🇦🇸", name: "American Samoa", abbr: "AS", code: "1-684" },
       { icon: "🇦🇩", name: "Andorra", abbr: "AD", code: "376" },
       { icon: "🇦🇴", name: "Angola", abbr: "AO", code: "244" },
       { icon: "🇦🇮", name: "Anguilla", abbr: "AI", code: "1-264" },
       { icon: "🇦🇶", name: "Antarctica", abbr: "AQ", code: "672" },
       { icon: "🇦🇬", name: "Antigua and Barbuda", abbr: "AG", code: "1-268" },
       { icon: "🇦🇷", name: "Argentina", abbr: "AR", code: "54" },
       { icon: "🇦🇲", name: "Armenia", abbr: "AM", code: "374" },
       { icon: "🇦🇼", name: "Aruba", abbr: "AW", code: "297" },
       {
         icon: "🇦🇺",
         name: "Australia",
         abbr: "AU",
         code: "61",
         suggested: true,
       },
       { icon: "🇦🇹", name: "Austria", abbr: "AT", code: "43" },
       { icon: "🇦🇿", name: "Azerbaijan", abbr: "AZ", code: "994" },
       { icon: "🇧🇸", name: "Bahamas", abbr: "BS", code: "1-242" },
       { icon: "🇧🇭", name: "Bahrain", abbr: "BH", code: "973" },
       { icon: "🇧🇩", name: "Bangladesh", abbr: "BD", code: "880" },
       { icon: "🇧🇧", name: "Barbados", abbr: "BB", code: "1-246" },
       { icon: "🇧🇾", name: "Belarus", abbr: "BY", code: "375" },
       { icon: "🇧🇪", name: "Belgium", abbr: "BE", code: "32" },
       { icon: "🇧🇿", name: "Belize", abbr: "BZ", code: "501" },
       { icon: "🇧🇯", name: "Benin", abbr: "BJ", code: "229" },
       { icon: "🇧🇲", name: "Bermuda", abbr: "BM", code: "1-441" },
       { icon: "🇧🇹", name: "Bhutan", abbr: "BT", code: "975" },
       { icon: "🇧🇴", name: "Bolivia", abbr: "BO", code: "591" },
       { icon: "🇧🇦", name: "Bosnia and Herzegovina", abbr: "BA", code: "387" },
       { icon: "🇧🇼", name: "Botswana", abbr: "BW", code: "267" },
       { icon: "🇧🇻", name: "Bouvet Island", abbr: "BV", code: "47" },
       { icon: "🇧🇷", name: "Brazil", abbr: "BR", code: "55" },
       {
         icon: "🇮🇴",
         name: "British Indian Ocean Territory",
         abbr: "IO",
         code: "246",
       },
       {
         icon: "🇻🇬",
         name: "British Virgin Islands",
         abbr: "VG",
         code: "1-284",
       },
       { icon: "🇧🇳", name: "Brunei Darussalam", abbr: "BN", code: "673" },
       { icon: "🇧🇬", name: "Bulgaria", abbr: "BG", code: "359" },
       { icon: "🇧🇫", name: "Burkina Faso", abbr: "BF", code: "226" },
       { icon: "🇧🇮", name: "Burundi", abbr: "BI", code: "257" },
       { icon: "🇰🇭", name: "Cambodia", abbr: "KH", code: "855" },
       { icon: "🇨🇲", name: "Cameroon", abbr: "CM", code: "237" },
       { icon: "🇨🇦", name: "Canada", abbr: "CA", code: "1", suggested: true },
       { icon: "🇨🇻", name: "Cape Verde", abbr: "CV", code: "238" },
       { icon: "🇰🇾", name: "Cayman Islands", abbr: "KY", code: "1-345" },
       {
         icon: "🇨🇫",
         name: "Central African Republic",
         abbr: "CF",
         code: "236",
       },
       { icon: "🇹🇩", name: "Chad", abbr: "TD", code: "235" },
       { icon: "🇨🇱", name: "Chile", abbr: "CL", code: "56" },
       { icon: "🇨🇳", name: "China", abbr: "CN", code: "86" },
       { icon: "🇨🇽", name: "Christmas Island", abbr: "CX", code: "61" },
       { icon: "🇨🇨", name: "Cocos (Keeling) Islands", abbr: "CC", code: "61" },
       { icon: "🇨🇴", name: "Colombia", abbr: "CO", code: "57" },
       { icon: "🇰🇲", name: "Comoros", abbr: "KM", code: "269" },
       {
         icon: "🇨🇩",
         name: "Congo, Democratic Republic of the",
         abbr: "CG",
         code: "243",
       },
       {
         icon: "🇨🇬",
         name: "Congo, Republic of the",
         abbr: "CD",
         code: "242",
       },
       { icon: "🇨🇰", name: "Cook Islands", abbr: "CK", code: "682" },
       { icon: "🇨🇷", name: "Costa Rica", abbr: "CR", code: "506" },
       { icon: "🇨🇮", name: "Cote d'Ivoire", abbr: "CI", code: "225" },
       { icon: "🇭🇷", name: "Croatia", abbr: "HR", code: "385" },
       { icon: "🇨🇺", name: "Cuba", abbr: "CU", code: "53" },
       { icon: "🇨🇼", name: "Curacao", abbr: "CW", code: "599" },
       { icon: "🇨🇾", name: "Cyprus", abbr: "CY", code: "357" },
       { icon: "🇨🇿", name: "Czech Republic", abbr: "CZ", code: "420" },
       { icon: "🇩🇰", name: "Denmark", abbr: "DK", code: "45" },
       { icon: "🇩🇯", name: "Djibouti", abbr: "DJ", code: "253" },
       { icon: "🇩🇲", name: "Dominica", abbr: "DM", code: "1-767" },
       { icon: "🇩🇴", name: "Dominican Republic", abbr: "DO", code: "1-809" },
       { icon: "🇪🇨", name: "Ecuador", abbr: "EC", code: "593" },
       { icon: "🇪🇬", name: "Egypt", abbr: "EG", code: "20" },
       { icon: "🇸🇻", name: "El Salvador", abbr: "SV", code: "503" },
       { icon: "🇬🇶", name: "Equatorial Guinea", abbr: "GQ", code: "240" },
       { icon: "🇪🇷", name: "Eritrea", abbr: "ER", code: "291" },
       { icon: "🇪🇪", name: "Estonia", abbr: "EE", code: "372" },
       { icon: "🇪🇹", name: "Ethiopia", abbr: "ET", code: "251" },
       {
         icon: "🇫🇰",
         name: "Falkland Islands (Malvinas)",
         abbr: "FK",
         code: "500",
       },
       { icon: "🇫🇴", name: "Faroe Islands", abbr: "FO", code: "298" },
       { icon: "🇫🇯", name: "Fiji", abbr: "FJ", code: "679" },
       { icon: "🇫🇮", name: "Finland", abbr: "FI", code: "358" },
       { icon: "🇫🇷", name: "France", abbr: "FR", code: "33", suggested: true },
       { icon: "🇬🇫", name: "French Guiana", abbr: "GF", code: "594" },
       { icon: "🇵🇫", name: "French Polynesia", abbr: "PF", code: "689" },
       {
         icon: "🇹🇫",
         name: "French Southern Territories",
         abbr: "TF",
         code: "262",
       },
       { icon: "🇬🇦", name: "Gabon", abbr: "GA", code: "241" },
       { icon: "🇬🇲", name: "Gambia", abbr: "GM", code: "220" },
       { icon: "🇬🇪", name: "Georgia", abbr: "GE", code: "995" },
       { icon: "🇩🇪", name: "Germany", abbr: "DE", code: "49", suggested: true },
       { icon: "🇬🇭", name: "Ghana", abbr: "GH", code: "233" },
       { icon: "🇬🇮", name: "Gibraltar", abbr: "GI", code: "350" },
       { icon: "🇬🇷", name: "Greece", abbr: "GR", code: "30" },
       { icon: "🇬🇱", name: "Greenland", abbr: "GL", code: "299" },
       { icon: "🇬🇩", name: "Grenada", abbr: "GD", code: "1-473" },
       { icon: "🇬🇵", name: "Guadeloupe", abbr: "GP", code: "590" },
       { icon: "🇬🇺", name: "Guam", abbr: "GU", code: "1-671" },
       { icon: "🇬🇹", name: "Guatemala", abbr: "GT", code: "502" },
       { icon: "🇬🇬", name: "Guernsey", abbr: "GG", code: "44" },
       { icon: "🇬🇼", name: "Guinea-Bissau", abbr: "GW", code: "245" },
       { icon: "🇬🇳", name: "Guinea", abbr: "GN", code: "224" },
       { icon: "🇬🇾", name: "Guyana", abbr: "GY", code: "592" },
       { icon: "🇭🇹", name: "Haiti", abbr: "HT", code: "509" },
       {
         icon: "🇭🇲",
         name: "Heard Island and McDonald Islands",
         abbr: "HM",
         code: "672",
       },
       {
         icon: "🇻🇦",
         name: "Holy See (Vatican City State)",
         abbr: "VA",
         code: "379",
       },
       { icon: "🇭🇳", name: "Honduras", abbr: "HN", code: "504" },
       { icon: "🇭🇰", name: "Hong Kong", abbr: "HK", code: "852" },
       { icon: "🇭🇺", name: "Hungary", abbr: "HU", code: "36" },
       { icon: "🇮🇸", name: "Iceland", abbr: "IS", code: "354" },
       { icon: "🇮🇳", name: "India", abbr: "IN", code: "91" },
       { icon: "🇮🇩", name: "Indonesia", abbr: "ID", code: "62" },
       {
         icon: "🇮🇷",
         name: "Iran, Islamic Republic of",
         abbr: "IR",
         code: "98",
       },
       { icon: "🇮🇶", name: "Iraq", abbr: "IQ", code: "964" },
       { icon: "🇮🇪", name: "Ireland", abbr: "IE", code: "353" },
       { icon: "🇮🇲", name: "Isle of Man", abbr: "IM", code: "44" },
       { icon: "🇮🇱", name: "Israel", abbr: "IL", code: "972" },
       { icon: "🇮🇹", name: "Italy", abbr: "IT", code: "39" },
       { icon: "🇯🇲", name: "Jamaica", abbr: "JM", code: "1-876" },
       { icon: "🇯🇵", name: "Japan", abbr: "JP", code: "81", suggested: true },
       { icon: "🇯🇪", name: "Jersey", abbr: "JE", code: "44" },
       { icon: "🇯🇴", name: "Jordan", abbr: "JO", code: "962" },
       { icon: "🇰🇿", name: "Kazakhstan", abbr: "KZ", code: "7" },
       { icon: "🇰🇪", name: "Kenya", abbr: "KE", code: "254" },
       { icon: "🇰🇮", name: "Kiribati", abbr: "KI", code: "686" },
       {
         icon: "🇰🇵",
         name: "Korea, Democratic People's Republic of",
         abbr: "KP",
         code: "850",
       },
       { icon: "🇰🇷", name: "Korea, Republic of", abbr: "KR", code: "82" },
       { icon: "🇽🇰", name: "Kosovo", abbr: "XK", code: "383" },
       { icon: "🇰🇼", name: "Kuwait", abbr: "KW", code: "965" },
       { icon: "🇰🇬", name: "Kyrgyzstan", abbr: "KG", code: "996" },
       {
         icon: "🇱🇦",
         name: "Lao People's Democratic Republic",
         abbr: "LA",
         code: "856",
       },
       { icon: "🇱🇻", name: "Latvia", abbr: "LV", code: "371" },
       { icon: "🇱🇧", name: "Lebanon", abbr: "LB", code: "961" },
       { icon: "🇱🇸", name: "Lesotho", abbr: "LS", code: "266" },
       { icon: "🇱🇷", name: "Liberia", abbr: "LR", code: "231" },
       { icon: "🇱🇾", name: "Libya", abbr: "LY", code: "218" },
       { icon: "🇱🇮", name: "Liechtenstein", abbr: "LI", code: "423" },
       { icon: "🇱🇹", name: "Lithuania", abbr: "LT", code: "370" },
       { icon: "🇱🇺", name: "Luxembourg", abbr: "LU", code: "352" },
       { icon: "🇲🇴", name: "Macao", abbr: "MO", code: "853" },
       {
         icon: "🇲🇰",
         name: "Macedonia, the Former Yugoslav Republic of",
         abbr: "MK",
         code: "389",
       },
       { icon: "🇲🇬", name: "Madagascar", abbr: "MG", code: "261" },
       { icon: "🇲🇼", name: "Malawi", abbr: "MW", code: "265" },
       { icon: "🇲🇾", name: "Malaysia", abbr: "MY", code: "60" },
       { icon: "🇲🇻", name: "Maldives", abbr: "MV", code: "960" },
       { icon: "🇲🇱", name: "Mali", abbr: "ML", code: "223" },
       { icon: "🇲🇹", name: "Malta", abbr: "MT", code: "356" },
       { icon: "🇲🇭", name: "Marshall Islands", abbr: "MH", code: "692" },
       { icon: "🇲🇶", name: "Martinique", abbr: "MQ", code: "596" },
       { icon: "🇲🇷", name: "Mauritania", abbr: "MR", code: "222" },
       { icon: "🇲🇺", name: "Mauritius", abbr: "MU", code: "230" },
       { icon: "🇾🇹", name: "Mayotte", abbr: "YT", code: "262" },
       { icon: "🇲🇽", name: "Mexico", abbr: "MX", code: "52" },
       {
         icon: "🇫🇲",
         name: "Micronesia, Federated States of",
         abbr: "FM",
         code: "691",
       },
       { icon: "🇲🇩", name: "Moldova, Republic of", abbr: "MD", code: "373" },
       { icon: "🇲🇨", name: "Monaco", abbr: "MC", code: "377" },
       { icon: "🇲🇳", name: "Mongolia", abbr: "MN", code: "976" },
       { icon: "🇲🇪", name: "Montenegro", abbr: "ME", code: "382" },
       { icon: "🇲🇸", name: "Montserrat", abbr: "MS", code: "1-664" },
       { icon: "🇲🇦", name: "Morocco", abbr: "MA", code: "212" },
       { icon: "🇲🇿", name: "Mozambique", abbr: "MZ", code: "258" },
       { icon: "🇲🇲", name: "Myanmar", abbr: "MM", code: "95" },
       { icon: "🇳🇦", name: "Namibia", abbr: "NA", code: "264" },
       { icon: "🇳🇷", name: "Nauru", abbr: "NR", code: "674" },
       { icon: "🇳🇵", name: "Nepal", abbr: "NP", code: "977" },
       { icon: "🇳🇱", name: "Netherlands", abbr: "NL", code: "31" },
       { icon: "🇳🇨", name: "New Caledonia", abbr: "NC", code: "687" },
       { icon: "🇳🇿", name: "New Zealand", abbr: "NZ", code: "64" },
       { icon: "🇳🇮", name: "Nicaragua", abbr: "NI", code: "505" },
       { icon: "🇳🇪", name: "Niger", abbr: "NE", code: "227" },
       { icon: "🇳🇬", name: "Nigeria", abbr: "NG", code: "234" },
       { icon: "🇳🇺", name: "Niue", abbr: "NU", code: "683" },
       { icon: "🇳🇫", name: "Norfolk Island", abbr: "NF", code: "672" },
       {
         icon: "🇲🇵",
         name: "Northern Mariana Islands",
         abbr: "MP",
         code: "1-670",
       },
       { icon: "🇳🇴", name: "Norway", abbr: "NO", code: "47" },
       { icon: "🇴🇲", name: "Oman", abbr: "OM", code: "968" },
       { icon: "🇵🇰", name: "Pakistan", abbr: "PK", code: "92" },
       { icon: "🇵🇼", name: "Palau", abbr: "PW", code: "680" },
       { icon: "🇵🇸", name: "Palestine, State of", abbr: "PS", code: "970" },
       { icon: "🇵🇦", name: "Panama", abbr: "PA", code: "507" },
       { icon: "🇵🇬", name: "Papua New Guinea", abbr: "PG", code: "675" },
       { icon: "🇵🇾", name: "Paraguay", abbr: "PY", code: "595" },
       { icon: "🇵🇪", name: "Peru", abbr: "PE", code: "51" },
       { icon: "🇵🇭", name: "Philippines", abbr: "PH", code: "63" },
       { icon: "🇵🇳", name: "Pitcairn", abbr: "PN", code: "870" },
       { icon: "🇵🇱", name: "Poland", abbr: "PL", code: "48" },
       { icon: "🇵🇹", name: "Portugal", abbr: "PT", code: "351" },
       { icon: "🇵🇷", name: "Puerto Rico", abbr: "PR", code: "1" },
       { icon: "🇶🇦", name: "Qatar", abbr: "QA", code: "974" },
       { icon: "🇷🇪", name: "Reunion", abbr: "RE", code: "262" },
       { icon: "🇷🇴", name: "Romania", abbr: "RO", code: "40" },
       { icon: "🇷🇺", name: "Russian Federation", abbr: "RU", code: "7" },
       { icon: "🇷🇼", name: "Rwanda", abbr: "RW", code: "250" },
       { icon: "🇧🇱", name: "Saint Barthelemy", abbr: "BL", code: "590" },
       { icon: "🇸🇭", name: "Saint Helena", abbr: "SH", code: "290" },
       { icon: "🇰🇳", name: "Saint Kitts and Nevis", abbr: "KN", code: "1-869" },
       { icon: "🇱🇨", name: "Saint Lucia", abbr: "LC", code: "1-758" },
       {
         icon: "🇲🇫",
         name: "Saint Martin (French part)",
         abbr: "MF",
         code: "590",
       },
       {
         icon: "🇵🇲",
         name: "Saint Pierre and Miquelon",
         abbr: "PM",
         code: "508",
       },
       {
         icon: "🇻🇨",
         name: "Saint Vincent and the Grenadines",
         abbr: "VC",
         code: "1-784",
       },
       { icon: "🇼🇸", name: "Samoa", abbr: "WS", code: "685" },
       { icon: "🇸🇲", name: "San Marino", abbr: "SM", code: "378" },
       { icon: "🇸🇹", name: "Sao Tome and Principe", abbr: "ST", code: "239" },
       { icon: "🇸🇦", name: "Saudi Arabia", abbr: "SA", code: "966" },
       { icon: "🇸🇳", name: "Senegal", abbr: "SN", code: "221" },
       { icon: "🇷🇸", name: "Serbia", abbr: "RS", code: "381" },
       { icon: "🇸🇨", name: "Seychelles", abbr: "SC", code: "248" },
       { icon: "🇸🇱", name: "Sierra Leone", abbr: "SL", code: "232" },
       { icon: "🇸🇬", name: "Singapore", abbr: "SG", code: "65" },
       {
         icon: "🇸🇽",
         name: "Sint Maarten (Dutch part)",
         abbr: "SX",
         code: "1-721",
       },
       { icon: "🇸🇰", name: "Slovakia", abbr: "SK", code: "421" },
       { icon: "🇸🇮", name: "Slovenia", abbr: "SI", code: "386" },
       { icon: "🇸🇧", name: "Solomon Islands", abbr: "SB", code: "677" },
       { icon: "🇸🇴", name: "Somalia", abbr: "SO", code: "252" },
       { icon: "🇿🇦", name: "South Africa", abbr: "ZA", code: "27" },
       {
         icon: "🇬🇸",
         name: "South Georgia and the South Sandwich Islands",
         abbr: "GS",
         code: "500",
       },
       { icon: "🇸🇸", name: "South Sudan", abbr: "SS", code: "211" },
       { icon: "🇪🇸", name: "Spain", abbr: "ES", code: "34" },
       { icon: "🇱🇰", name: "Sri Lanka", abbr: "LK", code: "94" },
       { icon: "🇸🇩", name: "Sudan", abbr: "SD", code: "249" },
       { icon: "🇸🇷", name: "Suriname", abbr: "SR", code: "597" },
       { icon: "🇸🇯", name: "Svalbard and Jan Mayen", abbr: "SJ", code: "47" },
       { icon: "🇸🇿", name: "Swaziland", abbr: "SZ", code: "268" },
       { icon: "🇸🇪", name: "Sweden", abbr: "SE", code: "46" },
       { icon: "🇨🇭", name: "Switzerland", abbr: "CH", code: "41" },
       { icon: "🇸🇾", name: "Syrian Arab Republic", abbr: "SY", code: "963" },
       {
         icon: "🇹🇼",
         name: "Taiwan, Province of China",
         abbr: "TW",
         code: "886",
       },
       { icon: "🇹🇯", name: "Tajikistan", abbr: "TJ", code: "992" },
       { icon: "🇹🇭", name: "Thailand", abbr: "TH", code: "66" },
       { icon: "🇹🇱", name: "Timor-Leste", abbr: "TL", code: "670" },
       { icon: "🇹🇬", name: "Togo", abbr: "TG", code: "228" },
       { icon: "🇹🇰", name: "Tokelau", abbr: "TK", code: "690" },
       { icon: "🇹🇴", name: "Tonga", abbr: "TO", code: "676" },
       { icon: "🇹🇹", name: "Trinidad and Tobago", abbr: "TT", code: "1-868" },
       { icon: "🇹🇳", name: "Tunisia", abbr: "TN", code: "216" },
       { icon: "🇹🇷", name: "Turkey", abbr: "TR", code: "90" },
       { icon: "🇹🇲", name: "Turkmenistan", abbr: "TM", code: "993" },
       {
         icon: "🇹🇨",
         name: "Turks and Caicos Islands",
         abbr: "TC",
         code: "1-649",
       },
       { icon: "🇹🇻", name: "Tuvalu", abbr: "TV", code: "688" },
       { icon: "🇺🇬", name: "Uganda", abbr: "UG", code: "256" },
       { icon: "🇺🇦", name: "Ukraine", abbr: "UA", code: "380" },
       { icon: "🇦🇪", name: "United Arab Emirates", abbr: "AE", code: "971" },
       { icon: "🇬🇧", name: "United Kingdom", abbr: "GB", code: "44" },
       {
         icon: "🇹🇿",
         name: "United Republic of Tanzania",
         abbr: "TZ",
         code: "255",
       },
       {
         icon: "🇺🇲",
         name: "United States",
         abbr: "US",
         code: "1",
         suggested: true,
       },
       { icon: "🇺🇾", name: "Uruguay", abbr: "UY", code: "598" },
       { icon: "🇻🇮", name: "US Virgin Islands", abbr: "VI", code: "1-340" },
       { icon: "🇺🇿", name: "Uzbekistan", abbr: "UZ", code: "998" },
       { icon: "🇻🇺", name: "Vanuatu", abbr: "VU", code: "678" },
       { icon: "🇻🇪", name: "Venezuela", abbr: "VE", code: "58" },
       { icon: "🇻🇳", name: "Vietnam", abbr: "VN", code: "84" },
       { icon: "🇼🇫", name: "Wallis and Futuna", abbr: "WF", code: "681" },
       { icon: "🇪🇭", name: "Western Sahara", abbr: "EH", code: "212" },
       { icon: "🇾🇪", name: "Yemen", abbr: "YE", code: "967" },
       { icon: "🇿🇲", name: "Zambia", abbr: "ZM", code: "260" },
       { icon: "🇿🇼", name: "Zimbabwe", abbr: "ZW", code: "263" },
     ]);
    
    const [countryFilter , setCountryFilter] = useState(undefined);
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        password: "",
        email: "",
        country:""
    });
    const [accountType, setAccountType] = useState("seller");
    const dispatch = useDispatch();
    const makeToast = useMakeToast();
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [openCsv, setOpenCsv] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalCounts, setTotalCounts] = useState( );
    const [blockModal,setBlockModal] = useState(false);
    const [userId, setUserId] = useState(0);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [searchTerm, setSearchTerm] = useState("");
    //images
    const [showModal, setShowModal] = useState(false);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
    const [blockFilter, setBlockFilter] = useState(null);
    const [verifiedFilter, setVerifiedFilter] = useState(null);
    const [accountTypeFilter, setAccountTypeFilter] = useState(null);
    const optionsBlockFilter = [
        { value: null, label: 'Block/UnBlock Users' },
        { value: true, label: 'Blocked Users' },
        { value: false, label: 'UnBlocked Users' },
    ];
    const optionsVerifiedFilter = [
        { value: null, label: 'Account Verified' },
        { value: true, label: 'Verified Users' },
        { value: false, label: 'UnVerified Users' },
    ];
    const [tableSkeleton, setTableSkeleton] = useState(false);
    const optionsAccountType = [
        { value: null , label: 'Account Type'},
        { value: 'seller', label: 'Seller' },
        { value: 'consultant', label: 'Consultant' },
        { value: 'supplier', label: 'Supplier' },
    ];
    const TableHeadings = ['User ID.',
        ' Profile Image','Full Name','Email',
        <FilterDropDown options={optionsVerifiedFilter} value={verifiedFilter} setValue={setVerifiedFilter} />,
        <FilterDropDown options={optionsAccountType} value={accountTypeFilter} setValue={setAccountTypeFilter} />,
        'City',
        <FilterDropDown options={countries} value={countryFilter} setValue={setCountryFilter} />,
        <FilterDropDown options={optionsBlockFilter} value={blockFilter} setValue={setBlockFilter} />,
        'Delete'];


    const location = useLocation();
    const handlePhotoClick = (index) => {
        setSelectedPhotoIndex(index);
        console.log('index',index);
        setShowModal(true);
    };
    const callUserList = async (type) => {
        const response = await allUsers(page,pageSize,searchTerm,{
            'blockFilter': blockFilter,
            'verifiedFilter': verifiedFilter,
            'countryFilter': countryFilter,
            'accountTypeFilter': type ? type : accountTypeFilter
        });
        if (response) {
          console.log(response,"user")
            setAllUserList(response?.data?.response);
            setTotalCounts(parseInt(response?.data?.totalUserCount));
            setCountries(prevState => [
                ...[{ value: undefined, label: 'Countries Filter' },{ value: 'empty', label: 'Not Available' }],
                ...(response?.data?.countries || [])
            ]);
            // dispatch(addAllUser(response?.data?.response));
        } else {
            setAllUserList([]);
        }
        setLoading(false);
    };

    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const type = searchParams.get('type');
        const typeToAccountType = {
            seller: 'seller',
            consultant: 'consultant',
            supplier: 'supplier',
        };
        setLoading(true);
        if (typeToAccountType[type] && type !== accountTypeFilter) {
          callUserList(type)
        }else{
        callUserList()
        }
    }, [page, pageSize,blockFilter,verifiedFilter,accountTypeFilter,countryFilter]);
 


    useEffect(() => {
        if (searchTerm !== "") {
            setTableSkeleton(true);
            callUserList().then(() => setTableSkeleton(false));
        }
    }, [searchTerm]);


    const handleBlockUnblock = (id) => {
        setUserId(id);
        setBlockModal(true)
    }
    const handleDelete = (id) => {
        setUserId(id);
        setOpenAlert(true);
    }
    const blockUnblockUserHandler = async () => {
        console.log("handle id", userId);
        const response = await blockUnblockUser(userId);
        if (response) {
            const newUserList = allUserList.map((el) => {
                if (el?._id === userId) {
                    el.isUserBlocked = !el.isUserBlocked;
                }
                return el;
            });
            setAllUserList(newUserList);
            const msg = response?.data?.message || "User blocked/unblocked successfully";
            makeToast(msg, "success", 3);
        } else {
            makeToast("User blocking/unblocking failed", "warn", 3);
        }
    };

  const deleteUserHandler = async () => {
    console.log("handle user id", userId);
    const response = await deleteUser({ userId: userId });
    console.log("response", response);
    if (response) {
      const newUserList = allUserList.filter((el) => el?._id !== userId);
      setAllUserList(newUserList);
      setTotalCounts(totalCounts - 1);
      makeToast("User Deleted Successfully", "success", 3);
    } else {
      makeToast("User Not Deleted", "warn", 3);
    }
  };

  const handleChangeRole = (e) => setAccountType(e?.target?.value);
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
    useEffect(() => {
        // getActivities().then(res => console.log('activity bhai =>>',res));
    }, []);


  const handleSubmit = async () => {
    setLoading(true);
    const response = await signUp({ accountType, ...user });
    if (response?.data?.success) {
      setLoading(false);
      setTimeout(()=>{

        setAllUserList([...allUserList, { accountType, ...user }]);
        makeToast(response?.data?.message || "User created successfully", "success", 3);

        
        callUserList()
        handleClose();
      },500)
    } else {
      setLoading(false);
      setTimeout(()=>{

        makeToast(response?.data?.message || "something wrong", "warn", 3);
      },500)
    }
  };

 

  if (loading) {  return <Loading loading={loading} /> };

  return (
    <>
      <ToastContainer />
      <TableContainer component={Paper}>
        <Box
          p={1}
          sx={{
            display: "flex",
            justifyContent: "end",
            borderRadius: "7px",
            marginBottom: "5px",
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onInput={(e) => setSearchTerm(e.target.value)}
            style={{ width: "100%", marginRight: "10px" }}
          />
          <button
            style={{
              padding: "4px",
              border: "none",
              outline: "none",
              backgroundColor: "#17594A",
              color: "white",
              minWidth: "100px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handleOpen}
          >
            <Typography>Add User</Typography>
          </button>
          <button
            style={{
              padding: "4px",
              border: "none",
              outline: "none",
              backgroundColor: "#17594A",
              marginLeft: "10px",
              color: "white",
              minWidth: "100px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => setOpenCsv(true)}
          >
            <Typography>Add User From Excel</Typography>
          </button>
        </Box>
        {/*Table Starts*/}
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {TableHeadings.map((heading) => (
                <TableCell>
                  <Typography sx={{ fontWeight: 700 }}>{heading}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableSkeleton ? (
              <TableSkeleton
                rowsLength={allUserList.length}
                circular={2}
                count={10}
              />
            ) : allUserList.length > 0 ? (
              allUserList.map(
                (
                  {
                    _id,
                    accountType: accountType1,
                    city,
                    country,
                    email,
                    firstName,
                    isUserBlocked,
                    isVerified,
                    lastName,
                    profilePic,
                  },
                  index
                ) => {
                  return (
                    <TableRow
                      key={_id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">
                        <Typography>{_id}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Box
                          sx={{
                            borderRadius: "50%",
                            overflow: "hidden",
                            width: "50px",
                            height: "50px",
                            boxShadow: "0 0 2px #888",
                          }}
                          onClick={() => handlePhotoClick(index)}
                        >
                          <img
                            src={profilePic ? profilePic : person}
                            style={{
                              scale: "1.1",
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                            alt={"Ima-ge"}
                          />
                        </Box>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Box>
                          {firstName} {lastName}
                        </Box>
                      </TableCell>
                      <TableCell align="left">{tableText(email)}</TableCell>
                      <TableCell align="left">
                        <Typography
                          sx={{ color: isVerified ? "green" : "red" }}
                        >
                          {isVerified ? "Verified" : "Un Verified"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">{accountType1}</TableCell>
                      <TableCell align="left"> {tableText(city)} </TableCell>
                      <TableCell align="left"> {tableText(country)} </TableCell>
                      <TableCell align="left">
                        <button
                          style={{
                            padding: "2px 5px",
                            minWidth: "80px",
                            border: "none",
                            outline: "none",
                            borderRadius: "4px",
                            backgroundColor: isUserBlocked
                              ? "#17594A"
                              : "#F29727",
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={() => handleBlockUnblock(_id)}
                        >
                          <Typography>
                            {isUserBlocked ? "UnBlock" : "Block"}
                          </Typography>
                        </button>
                      </TableCell>
                      <TableCell align="left">
                        <button
                          style={{
                            padding: "2px 5px",
                            minWidth: "80px",
                            border: "none",
                            outline: "none",
                            borderRadius: "4px",
                            backgroundColor: "#F24C3D",
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDelete(_id)}
                        >
                          <Typography>Delete</Typography>
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                }
              )
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <Typography>No User Found</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCounts}
        page={page}
        onPageChange={(e, newPage) => setPage(newPage)}
        rowsPerPage={pageSize}
        onRowsPerPageChange={(e) => {
          setPageSize(parseInt(e.target.value, 10));
          setPage(0);
        }}
      />
      {/*Table Ends*/}
      {/*Modal Start*/}
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "50%",
                }}
              >
                <Typography>First Name</Typography>
                <input
                  type="text"
                  value={user?.firstName}
                  name="firstName"
                  onChange={handleChange}
                  style={{
                    width: "90%",
                    height: "50px",
                    borderRadius: "7px",
                    border: "1px solid #888",
                    outline: "none",
                    padding: "0px 5px",
                    fontWeight: "600",
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "50%",
                }}
              >
                <Typography>Last Name</Typography>
                <input
                  name="lastName"
                  onChange={handleChange}
                  value={user?.lastName}
                  type="text"
                  style={{
                    width: "90%",
                    height: "50px",
                    borderRadius: "7px",
                    border: "1px solid #888",
                    outline: "none",
                    padding: "0px 5px",

                    fontWeight: "600",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                with: "100%",
              }}
            >
              <Typography my={1}>Email</Typography>
              <input
                name="email"
                onChange={handleChange}
                value={user?.email}
                type="email"
                required
                style={{
                  width: "95%",
                  height: "50px",
                  borderRadius: "7px",
                  border: "1px solid #888",
                  outline: "none",
                  padding: "0px 5px",

                  fontWeight: "600",
                }}
              />
            </Box>
            <Box
              sx={{
                with: "100%",
              }}
            >
              <FormControl sx={{ mt: 3, width: "90%", height: "50px" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select Country
                </InputLabel>
                <Select
                  sx={{
                    height: "50px",
                    // border: "none",
                    outline: "none",
                    borderRadius: "7px",
                    // border: "1px solid #888",
                  }}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={user.country}
                  label="Select Country"
                  onChange={handleChange}
                  name="country"
                >
                  {countriesData.map((ele) => {
                    return <MenuItem value={ele.name}>{ele.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>

            <Box my={1} sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "50%",
                }}
              >
                <FormControl sx={{ mt: 3, width: "90%", height: "50px" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Account Type
                  </InputLabel>
                  <Select
                    sx={{
                      height: "50px",
                      // border: "none",
                      outline: "none",
                      borderRadius: "7px",
                      // border: "1px solid #888",
                    }}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={accountType}
                    label="Account Type"
                    onChange={handleChangeRole}
                  >
                    <MenuItem value="seller">Seller</MenuItem>
                    <MenuItem value="consultant">Consultant</MenuItem>
                    <MenuItem value="supplier">Supplier</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  width: "50%",
                }}
              >
                <Typography>Password</Typography>
                <input
                  name="password"
                  value={user?.password}
                  type="password"
                  onChange={handleChange}
                  style={{
                    width: "90%",
                    height: "50px",
                    borderRadius: "7px",
                    border: "1px solid #888",
                    outline: "none",
                    padding: "0px 5px",
                    fontWeight: "600",
                  }}
                />
              </Box>
            </Box>
            <Box>
              <button
                style={{
                  width: "95%",
                  height: "40px",
                  backgroundColor: "#539165",
                  border: "none",
                  outline: "none",
                  borderRadius: "7px",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                  onClick={handleSubmit}
                >
                  Create User
                </Typography>
              </button>
            </Box>
          </Box>
        </Modal>
      </Box>
      <CSVUploadModal openCsv={openCsv} setCsvOpen={setOpenCsv} />
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0",
          margin: "0",
          height: "100vh",
        }}
      >
        <div
          style={{
            maxWidth: "900px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img
              src={
                allUserList[selectedPhotoIndex]?.profilePic
                  ? allUserList[selectedPhotoIndex]?.profilePic
                  : person
              }
              alt={"serKi"}
              style={{
                maxWidth: "900px",
                minHeight: "900px",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </Modal>
      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        title={"Delete User"}
        functionToPerform={deleteUserHandler}
      />
      <AlertDialog
        open={blockModal}
        setOpen={setBlockModal}
        title={"Block User"}
        functionToPerform={blockUnblockUserHandler}
      />
    </>
  );
};

export default UserPage;
