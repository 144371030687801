import { getActivities} from "../../api/api";
import {
    Box, Typography, TextField,
    TableRow, TableHead, TableContainer, Table, TableBody, TableCell,
    TablePagination, Paper
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import FilterDropDown from "../../components/filterDropDown";
import TableSkeleton from "../../components/TableSkeleton";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
// export const socket = io.connect(URL, { transports: ["websocket"] });


const ActivitiesPage = () => {
    const users = useSelector((state) => state.users?.userInfo);
    const navigate= useNavigate();
        useEffect(() => {
            if (users.accountType !== 'admin'){
                navigate('/');
            }
        },[users]);
    const [loading, setLoading] = useState(true);
    const [activities, setActivities] = useState([]);
    const [page, setPage] = useState(0);
    const [totalCounts, setTotalCounts] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [actionTypeFilter, setActionTypeFilter] = useState(null);
    const optionsActionTypeFilter =
        [{ value: null, label: 'Action' } , { value: 'blocked', label: 'Blocked' },
        {value:'unblocked',label: 'Un Blocked'},{value:'deleted',label:'Deleted'}];
    const tableHead = ["Sr. No","Admin ID","Admin Name",
        <FilterDropDown options={optionsActionTypeFilter} value={actionTypeFilter} setValue={setActionTypeFilter} />
        ,"User ID","User Name","Date/Time"];
    const tableHeadCell = (data) => {
        return (
            <TableCell align="left">
                <Typography sx={{ fontWeight: 700 }}
                >{data}</Typography>
            </TableCell>
        );
    };
    const tableCell = (data,idx) => {
        return (
            <TableCell sx={{ color : idx === 3 ? 'red':''  }} align="left">
                <Typography>{data}</Typography>
            </TableCell>
        );
    };
     const tableRowBody = ( admin, actionType, user,date,_id , idx) => {
        return (
            <TableRow
                key={_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
                {[`${idx+1}`,admin?._id,`${admin.firstName} ${admin.lastName}`,actionType,
                    user._id,`${user.firstName} ${user.lastName}`,date].map((data,idx) => tableCell(data,idx))}
            </TableRow>
        );
    };
    const [tableSkeleton,setTableSkeleton] = useState(false);
    const callActivity = async () => {
        const response = await getActivities(page,pageSize,searchTerm,actionTypeFilter);
        if (response?.data?.success) {
            setActivities(response?.data?.data);
            setTotalCounts(parseInt(response?.data?.totalCount));
        } else {
            setActivities([]);
        }
    };

    useEffect(() => {
       setLoading(true);
       setTableSkeleton(true);
       callActivity().then(()=> setLoading(false)).then(()=> setTableSkeleton(false));
    }, []);


    useEffect(() => {
           setTableSkeleton(true);
            callActivity().then(()=> setTableSkeleton(false));
    }, [searchTerm,page, pageSize,actionTypeFilter]);

  if (loading) {
    return (
      <>
        <Loading loading={loading} />
      </>
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
          <Box
              p={1}
              sx={{
                  display: "flex",
                  justifyContent: "end",
                  borderRadius: "7px",
                  marginBottom: "5px",
              }}
          >
              <TextField
                  label="Search"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onInput={(e) => setSearchTerm(e.target.value)}
                  style={{ width: "100%", marginRight: "10px" }}
              />
          </Box>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>{tableHead.map((name,idx) => tableHeadCell(name))}</TableRow>
          </TableHead>
          <TableBody>
            {tableSkeleton ?  <TableSkeleton rowsLength={activities.length} count={7} />  :
                activities.map(({ admin, actionType, user,createdAt,_id },idx) => {
             const date = new Date(createdAt).toLocaleString();
                return tableRowBody( admin, actionType, user,date,_id,idx);
            })}
          </TableBody>
        </Table>
      </TableContainer>
        <TablePagination
            component="div"
            count={totalCounts}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => {
                setPageSize(parseInt(e.target.value, 10));
                setPage(0);
            }}
        />
    </>
  );
};

export default ActivitiesPage;
