import { Box, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createPrivacyPolicy, GetRequest } from "../../api/api";
import useMakeToast from "../../hooks/makeToast";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../components/Loading";
import TextField from "@mui/material/TextField";
import styles from "./allBlog.module.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import useApi from "../../hooks/useApi";

import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import Quill from "quill";
import { useSelector } from "react-redux";

// Extend the Quill Image Blot
const BlockEmbed = Quill.import("blots/block/embed");

class CustomImageBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute("src", value.url);
    node.setAttribute("alt", value.alt);
    return node;
  }

  static value(node) {
    return {
      url: node.getAttribute("src"),
      alt: node.getAttribute("alt"),
    };
  }
}

CustomImageBlot.blotName = "customImage";
CustomImageBlot.tagName = "img";

// Register the custom blot
Quill.register(CustomImageBlot);

const Blogs = () => {
  const users = useSelector((state) => state.users?.userInfo);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { data, loading, error, post, put } = useApi();
  const {
    data: draftData,
    loading: draftLoading,
    error: draftError,
    post: draftPost,
  } = useApi();

  const {
    data: editBlogData,
    loading: editBlogLoading,
    error: editBlogError,
    get,
  } = useApi();

  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
    get: getCategory,
  } = useApi();

  const theme = useTheme();
  const [value, setValue] = useState("");
  const makeToast = useMakeToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [fileDataUrl, setFileDataUrl] = useState(null);
  const [fileType, setFileType] = useState("");

  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [pageRoute, setPageRoute] = useState("");
  const [blogmainHeading, setBlogMainHeading] = useState("");
  const [metaAuthor, setMetaAuthor] = useState("");
  const [fbLink, setFbLink] = useState("");

  const [allCategoryData, setAllCategoryData] = useState([]);

  const [selectCategory, setSelectCategory] = useState("");

  const [bannerImgAlt, setBannerImgAlt] = useState("");

  const [blogId, setBlogId] = useState("");
  const [bannerImgName, setBannerImgName] = useState({
    imgName: "Select",
    imgValue: "",
  });
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setMetaAuthor(users.firstName + " " + users.lastName);
  }, [users]);

  useEffect(() => {
    const paramValue = searchParams.get("id");
    if (paramValue) {
      setBlogId(paramValue);
      setIsEdit(true);
      get(`/api/blog/get-blog/${paramValue}`);
    }
    getCategory("/api/blog/category?isAdmin=true");
  }, []);

  // get edit blog
  useEffect(() => {
    if (editBlogLoading) {
      setIsLoading(true);
      return;
    }
    if (editBlogError) {
      // setIsError(
      //   editBlogError?.response?.data?.message || "internal server error"
      // );
      toast(editBlogError?.response?.data?.message || "internal server error");
      setIsLoading(false);
      return;
    }
    if (editBlogData) {
      setIsLoading(false);
      let data = editBlogData.data;
      if (data) {
        setValue(data?.content || "");
        setPageRoute(data?.pageRoute || "");
        setBlogMainHeading(data?.title || "");
        setMetaTitle(data?.tags?.title || "");
        setMetaDescription(data?.tags?.description || "");
        setFbLink(data?.tags?.facebookLink || "");

        setMetaKeywords(data?.tags?.keywords || "");
        setMetaAuthor(data?.tags?.author || "");
        setSelectCategory(data?.blogCategoryId || "");

        setBannerImgAlt(data?.bannerImg?.alt || "");

        setBannerImgName({
          imgName: "select",
          imgValue: data?.bannerImg?.src || "",
        });
        setFileDataUrl(data?.bannerImg?.src || "");
        setFileType("image/");
      }

      console.log(editBlogData, "editBlogData");
    }
  }, [editBlogLoading, editBlogData, editBlogError]);

  //post blog
  useEffect(() => {
    if (loading) {
      setIsLoading(true);
      return;
    }
    if (error) {
      console.log(error);
      // setIsError(error?.response?.data?.message || "internal server error");
      toast(error?.response?.data?.message || "internal server error");

      setIsLoading(false);
      return;
    }
    if (data) {
      setIsLoading(false);
      toast(data.message);

      setTimeout(() => {
        navigate("/blogs");
      }, 3000);
    }
  }, [data, loading, error]);

  //draft blog
  useEffect(() => {
    if (draftLoading) {
      setIsLoading(true);
      return;
    }
    if (draftError) {
      console.log(draftError);
      setIsError(
        draftError?.response?.data?.message || "internal server error"
      );
      setIsLoading(false);
      return;
    }
    if (draftData) {
      setIsLoading(false);
      toast(`Post has been saved in draft`);
      console.log(draftData);

      setTimeout(() => {
        navigate("/blogs");
      }, 3000);
    }
  }, [draftData, draftLoading, draftError]);

  // get category
  useEffect(() => {
    if (categoryLoading) {
      setIsLoading(true);
    }
    if (categoryError) {
      setIsLoading(false);
      setIsError(
        categoryError?.response?.data?.message || "internal server error"
      );
    }
    if (categoryData) {
      setIsLoading(false);
      setAllCategoryData(categoryData.data);
    }
  }, [categoryData, categoryError, categoryLoading]);

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const imageUrlToBase64 = async (url) => {
    if (!url) {
      return;
    }
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = () => reject(new Error("Error reading file"));
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to Base64:", error);
      throw error;
    }
  };

  const postBlogs = async () => {
    if (blogmainHeading.trim().length == 0) {
      makeToast("Please write Blog Main Heading", "error", 3);
      return false;
    }
    if (selectCategory.trim().length == 0) {
      makeToast("Please select blog category", "error", 3);
      return false;
    }

    if (!value) {
      makeToast("Please write some data", "error", 3);
      return false;
    }
    if (metaTitle.trim().length === 0) {
      makeToast("Please write metaTitle", "error", 3);
      return false;
    }
    if (metaDescription.trim().length === 0) {
      makeToast("Please write metaDescription", "error", 3);
      return false;
    }
    if (metaKeywords.trim().length === 0) {
      makeToast("Please write metaKeywords", "error", 3);
      return false;
    }
    // if (metaAuthor.trim().length === 0) {
    //   makeToast("Please write author", "error", 3);
    //   return false;
    // }

    // const facebookRegex =
    //   /https?:\/\/(www\.)?linkedin\.com(\/in\/[A-Za-z0-9_-]+\/?)?$/;

    const trimmedFbLink = fbLink.trim();
    if (trimmedFbLink.length==0) {
      makeToast("Please enter a Linkedin link", "error", 3);
      return false;
    }

    if (pageRoute.trim().length === 0) {
      makeToast("Please write pageRoute", "error", 3);
      return false;
    }

    if (bannerImgAlt.trim().length === 0) {
      makeToast("Please write banner image alt", "error", 3);
      return false;
    }

    if (!isEdit) {
      if (bannerImgName.imgName == "Select") {
        makeToast("Please select banner image", "error", 3);
        return false;
      }
    }
    let base64BannerImg;

    if (bannerImgName.imgName == "select") {
      base64BannerImg = await imageUrlToBase64(bannerImgName.imgValue);
    } else {
      base64BannerImg = await convertFileToBase64(bannerImgName.imgValue);
    }

    const blogData = {
      content: value,
      title: blogmainHeading,
      blogCategoryId: selectCategory,

      tags: {
        title: metaTitle,
        description: metaDescription,
        keywords: metaKeywords,
        author: metaAuthor,
        facebookLink: fbLink,
      },
      pageRoute: pageRoute,
      blogId: blogId,

      bannerImg: {
        src: base64BannerImg,
        alt: bannerImgAlt,
      },

      activeStatus: true,
    };

    console.log(blogData);

    try {
      // if (!isEdit) {
      post("/api/blog/publish-blog", blogData);
      // }
      //  else {
      //   put(`/api/blog/edit-blog/${blogId}`, blogData);
      // }
    } catch (err) {
      // setIsError(err);
      console.log(err, "sdfsf");
      toast(err);
    }
  };

  // const selectBlogBanner = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     if (file.type !== "image/webp") {
  //       makeToast("Only WEBP images are allowed.", "error", 3);

  //       return;
  //     }
  //   }

  //   setBannerImgName({ imgName: file.name, imgValue: file });

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onload = (e) => {
  //       setFileDataUrl(e.target.result);
  //       setFileType(file.type);
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };

  const selectBlogBanner = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const originalImage = new Image();
      originalImage.src = e.target.result;

      originalImage.onload = () => {
        // Create a canvas element
        const canvas = document.createElement("canvas");
        canvas.width = originalImage.width;
        canvas.height = originalImage.height;
        const context = canvas.getContext("2d");
        context.drawImage(originalImage, 0, 0);

        // Convert to WebP and get base64 data
        canvas.toBlob((blob) => {
          if (!blob) {
            makeToast("Failed to convert image to WebP", "error", 3);
            return;
          }

          const webpReader = new FileReader();
          webpReader.onloadend = () => {
            const base64data = webpReader.result;

            setFileDataUrl(base64data);
            setFileType("image/webp");
            setBannerImgName({ imgName: file.name, imgValue: blob });
          };
          webpReader.readAsDataURL(blob);
        }, "image/webp");
      };
    };
    reader.readAsDataURL(file);
  };

  const saveDraft = async () => {
    if (selectCategory.trim().length == 0) {
      makeToast("Please select blog category", "error", 3);
      return false;
    }

    let base64BannerImg = "";
    if (bannerImgName.imgValue) {
      if (bannerImgName.imgName == "select") {
        base64BannerImg = await imageUrlToBase64(bannerImgName.imgValue);
      } else {
        base64BannerImg = await convertFileToBase64(bannerImgName.imgValue);
      }
    }

    const blogData = {
      content: value,
      title: blogmainHeading,
      blogCategoryId: selectCategory,
      tags: {
        title: metaTitle,
        description: metaDescription,
        keywords: metaKeywords,
        author: metaAuthor,
      },
      pageRoute: pageRoute,

      bannerImg: {
        src: base64BannerImg,
        alt: bannerImgAlt,
      },
      blogId: blogId,
      activeStatus: false,
    };
    console.log(blogData);
    draftPost("/api/blog/save-blog", blogData);
  };

  const handelBlogCategory = (e) => {
    setSelectCategory(e.target.value);
  };

  const quillRef = useRef(null);

  // Custom image handler
  // const imageHandler = useCallback(() => {
  //   // Access the Quill instance using quillRef
  //   const quill = quillRef.current?.getEditor();
  //   if (!quill) {
  //     console.error("Quill editor not found");
  //     return;
  //   }

  //   // Create an input element for file upload
  //   const input = document.createElement("input");
  //   input.type = "file";
  //   input.accept = "image/webp";
  //   input.onchange = async (event) => {
  //     const file = event.target.files?.[0];
  //     if (file) {
  //       console.log(file, "file");
  //       if (file.type != "image/webp") {
  //         makeToast("image allowed only in WEBP format", "error", 3);
  //         return;
  //       }
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         const url = reader.result;

  //         // Prompt user for alt text
  //         const altText = prompt("Enter alt text for the image:", "");
  //         if (altText !== null) {
  //           const range = quill.getSelection();
  //           if (range) {
  //             // Use insertEmbed to insert image with alt text
  //             quill.insertEmbed(
  //               range.index,
  //               "customImage",
  //               {
  //                 url: url,
  //                 alt: altText,
  //               },
  //               "user"
  //             );

  //             // Move the cursor position after the inserted image
  //             quill.setSelection(range.index + 1);
  //           }
  //         }
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   };
  //   input.click();
  // }, []);

  const imageHandler = useCallback(() => {
    // Access the Quill instance using quillRef
    const quill = quillRef.current?.getEditor();
    if (!quill) {
      console.error("Quill editor not found");
      return;
    }

    // Create an input element for file upload
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*"; // Accept all image types
    input.onchange = async (event) => {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const originalImage = new Image();
          originalImage.src = reader.result;

          originalImage.onload = () => {
            // Create a canvas element
            const canvas = document.createElement("canvas");
            canvas.width = originalImage.width;
            canvas.height = originalImage.height;
            const context = canvas.getContext("2d");
            context.drawImage(originalImage, 0, 0);

            // Convert to WebP and get base64 data
            canvas.toBlob((blob) => {
              if (!blob) {
                makeToast("Failed to convert image to WebP", "error", 3);
                return;
              }

              const reader = new FileReader();
              reader.onloadend = () => {
                const base64data = reader.result;

                // Prompt user for alt text
                const altText = prompt("Enter alt text for the image:", "");
                if (altText !== null) {
                  const range = quill.getSelection();
                  if (range) {
                    // Use insertEmbed to insert image with alt text
                    quill.insertEmbed(
                      range.index,
                      "customImage",
                      {
                        url: base64data,
                        alt: altText,
                      },
                      "user"
                    );

                    // Move the cursor position after the inserted image
                    quill.setSelection(range.index + 1);
                  }
                }
              };
              reader.readAsDataURL(blob);
            }, "image/webp");
          };
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  }, []);

  const modules = {
    toolbar: {
      container: [
        // [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };

  return (
    <>
      <ToastContainer />
      {isError && (
        <p className={`error-msg-p ${styles["blog-error"]}`}>{isError}</p>
      )}

      {isLoading && <Loading loading={isLoading} />}

      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <h3 className={styles["blog-main-heading"]}>
            Select Blog Main Heading
          </h3>

          <div className={styles["for-center"]}>
            <TextField
              id="outlined-basic"
              label="Blog Heading"
              variant="outlined"
              value={blogmainHeading}
              onChange={(e) => setBlogMainHeading(e.target.value)}
              className={styles["blog-heading-text-box"]}
            />
          </div>

          <h3 className={styles["blog-main-heading"]}>Select Blog Category</h3>

          <div className={styles["for-center"]}>
            <FormControl className="w-[60%] blog-heading-text-box">
              <InputLabel id="demo-simple-select-label">
                Select Category
              </InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectCategory}
                label="Age"
                onChange={handelBlogCategory}
                // className={styles["blog-heading-text-box"]}
              >
                {allCategoryData.map((ele) => (
                  <MenuItem
                    key={ele._id}
                    value={ele._id}
                    selected={ele._id === selectCategory}
                  >
                    {ele.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <h3 className={styles["blog-main-heading"]}>
            Select Blog Banner Image
          </h3>
          <input
            type="file"
            id="banner-id"
            className={styles["file-input"]}
            onChange={selectBlogBanner}
          />
          {fileDataUrl && fileType.startsWith("image/") && (
            <div className={styles["banner-img-wrapper"]}>
              <img
                src={fileDataUrl}
                alt="Preview"
                style={{ maxWidth: "100%" }}
              />
            </div>
          )}
          <div className={styles["for-center"]}>
            <label htmlFor="banner-id" className={styles["select-img-name"]}>
              {bannerImgName.imgName}
            </label>
          </div>

          <div className={styles["label-text-box-wrapper"]}>
            <label htmlFor="metaTitle">Banner Image Alt:</label>

            <TextField
              id="outlined-basic"
              label="Banner alt"
              variant="outlined"
              value={bannerImgAlt}
              onChange={(e) => setBannerImgAlt(e.target.value)}
            />
          </div>

          <div>
            <h3 className={styles["blog-main-heading"]}>Meta</h3>

            <div className={styles["all-label-div-wrapper"]}>
              <div className={styles["label-text-box-wrapper"]}>
                <label htmlFor="metaTitle">Title:</label>

                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
              </div>

              <div className={styles["label-text-box-wrapper"]}>
                <label htmlFor="metaDescription">Description:</label>
                <textarea
                  id="metaDescription"
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                  required
                  className={styles["text-area"]}
                  placeholder="Enter Description"
                  rows={1}
                ></textarea>
              </div>

              <div className={styles["label-text-box-wrapper"]}>
                <label htmlFor="metaKeywords">Keywords:</label>

                <TextField
                  id="outlined-basic"
                  label="Keywords"
                  variant="outlined"
                  value={metaKeywords}
                  onChange={(e) => setMetaKeywords(e.target.value)}
                />
              </div>
              {/* <div className={styles["label-text-box-wrapper"]}>
                <label htmlFor="metaKeywords">Author:</label>

                <TextField
                  id="outlined-basic"
                  label="Author"
                  variant="outlined"
                  value={metaAuthor}
                  onChange={(e) => setMetaAuthor(e.target.value)}
                />
              </div> */}

              <div className={styles["label-text-box-wrapper"]}>
                <label htmlFor="metaKeywords">Page Route:</label>

                <TextField
                  id="outlined-basic"
                  label="Route"
                  variant="outlined"
                  value={pageRoute}
                  onChange={(e) => setPageRoute(e.target.value)}
                />
              </div>

              <div className={styles["label-text-box-wrapper"]}>
                <label htmlFor="metaKeywords">Linkedin Profile Link:</label>

                <TextField
                  id="outlined-basic"
                  label="Linkedin Profile Link"
                  variant="outlined"
                  value={fbLink}
                  onChange={(e) => setFbLink(e.target.value)}
                />
              </div>
            </div>
          </div>
          <Box
            sx={{
              bgcolor: `${theme.palette.background.light}`,
              alignItems: "center",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              boxShadow: "0 0 2px rgba(0,0,0,0.5)",
            }}
            p={1}
            mx={1.1}
            mt={1}
          >
            <Typography
              s={{
                fontSize: "18px",
                fontWeight: "600",
                color: `${theme.palette.text.primary}`,
                textAlign: "center",
              }}
            >
              Write Blog Content Here
            </Typography>
          </Box>
          <Box px={1}>
            <ReactQuill
              ref={quillRef}
              // f={(el) => {
              //   quill = el;
              // }}
              modules={modules}
              theme="snow"
              onChange={setValue}
              value={value}
              placeholder="Content goes here..."
              height="100vh"
              width="100%"
              style={{
                color: `${theme.palette.background.primary}`,
              }}
            />

            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                padding: "3%",
              }}
            >
              <button
                style={{
                  backgroundColor: "orange",
                  padding: "7px 14px",
                  outline: "none",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  color: "white",
                  letterSpacing: "1px",
                  fontSize: "16px",
                  fontWeight: "500",
                  width: "max-content",
                  whiteSpace: "nowrap",
                }}
                onClick={saveDraft}
              >
                Save to Draft
              </button>

              <button
                style={{
                  backgroundColor: "#285430",
                  padding: "7px 14px",
                  outline: "none",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  color: "white",
                  letterSpacing: "1px",
                  fontSize: "16px",
                  fontWeight: "500",
                  width: "max-content",
                  whiteSpace: "nowrap",
                }}
                onClick={postBlogs}
              >
                {!isEdit ? "Post" : "Update Blog"}
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* )} */}
    </>
  );
};

export default Blogs;
