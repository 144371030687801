import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const request = async (method, endPoint, requestData, queryParams) => {
    let config = {
      method,
      url: apiUrl + endPoint,
      data: requestData,
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      setLoading(true);
      setData(null);
      const response = await axios(config);
      setData(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  //For HTTP GET Method
  const get = (url, queryParams = {}) => {
    return request("GET", url, {}, queryParams);
  };

  //For HTTP POST Method
  const post = (url, requestData, queryParams = {}) => {
    return request("POST", url, requestData, queryParams);
  };

  //For HTTP DELETE Method
  const del = (url, queryParams) => {
    return request("DELETE", url, {}, queryParams);
  };

  //For HTTP PUT Method
  const put = (url, requestData, queryParams = {}) => {
    return request("PUT", url, requestData, queryParams);
  };

  //For HTTP PATCH Method
  const patch = (url, requestData, queryParams = {}) => {
    return request("PATCH", url, requestData, queryParams);
  };

  return { data, loading, error, get, post, del, put, patch };
};

export default useApi;
