import * as React from 'react';
import { TableCell, TableRow, Skeleton } from '@mui/material';

export default function TableSkeleton({ count, circular = 0,rowsLength = 10 }) {
    rowsLength = Math.min(Math.max(rowsLength, 1), 10);
    const mad = Array.from({ length: count }, (_, index) => index + 1);
    const rows = Array.from({ length: rowsLength }, (_, index) => index + 1);

    return (
        <>
            {rows.map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                    {mad.map((_, cellIndex) => (
                        <TableCell key={cellIndex} align="center">
                            <Skeleton
                                variant={circular === _ ? 'circular' : undefined}
                                width={circular === _ ? 50 : 100}
                                height={circular === _ ? 50 : 30}
                                animation="wave"
                            />
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </>
    );
}
