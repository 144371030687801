import { useSelector, useDispatch } from "react-redux";
import { addAllUser } from "../../redux/slices/userSlice";
import {allUsers, blockUnblockUser, deleteUser, PostData} from "../../api/api";
import TableSkeleton from "../../components/TableSkeleton";
import {
    Box, Typography, TextField, Modal, InputLabel, MenuItem, FormControl,
    TableRow, TableHead, TableContainer, Table, TableBody, TableCell,
    TablePagination, Select, Paper, Button, ButtonGroup
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useMakeToast from "../../hooks/makeToast";
import Loading from "../../components/Loading";
import person from "../../assets/svg/person.svg";
import { io } from "socket.io-client";
import {ToastContainer} from "react-toastify";
import AlertDialog from "../../components/AlertDialog";
import FilterDropDown from "../../components/filterDropDown";
import {useNavigate} from "react-router-dom";
// export const socket = io.connect(URL, { transports: ["websocket"] });

const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "1px solid #888",
    boxShadow: 24,
    borderRadius: "8px",
    p: 4,
};

const Admins = () => {
    const users = useSelector((state) => state.users?.userInfo);
    const navigate= useNavigate();
    useEffect(() => {
        if (users.accountType !== 'admin'){
            navigate('/');
        }
    },[users]);
    const [loading, setLoading] = useState(true);
    const [allUserList, setAllUserList] = useState([]);
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        password: "",
        email: "",
    });
    const userInfo = useSelector((state) => state.users.userInfo.accountType);
    const makeToast = useMakeToast();
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalCounts, setTotalCounts] = useState(1);
    const [blockModal,setBlockModal] = useState(false);
    const [userId, setUserId] = useState(0);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [tableSkeleton, setTableSkeleton] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    //images
    const [showModal, setShowModal] = useState(false);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
    const [blockFilter, setBlockFilter] = useState(null);
    const optionsBlockFilter = [
        { value: null, label: 'Block/UnBlock' },
        { value: true, label: 'Blocked' },
        { value: false, label: 'UnBlocked' },
    ];
    const tableHead = ['User ID.', 'Profile Image','Full Name','Email','Account Type'
        ,<FilterDropDown options={optionsBlockFilter} value={blockFilter} setValue={setBlockFilter} />
        ,'Delete']
    const handlePhotoClick = (index) => {
        setSelectedPhotoIndex(index);
        console.log('index',index);
        setShowModal(true);
    };

    const callUserList = async () => {
        const response = await allUsers(page,pageSize,searchTerm,{
            'SubAdmin': true,
            'blockFilter': blockFilter
        });
        if (response) {
            setAllUserList(response?.data?.response);
            setTotalCounts(parseInt(response?.data?.totalUserCount));
            // dispatch(addAllUser(response?.data?.response));
            setLoading(false);
        } else {
            setLoading(false);
            setAllUserList([]);
        }
    };
    // useEffect(() => {
    //     socket.on("connection", () => {
    //         console.log("Connected to Socket.IO server");
    //     });
    //
    //     socket.on("disconnect", () => {
    //         console.log("Disconnected from Socket.IO server");
    //     });
    //     socket.emit("addUser", 823749827349);
    //
    //     socket.on("getUsers", (data) => {
    //         console.log(data, "data all");
    //     });
    // }, []);
    useEffect(() => {
        if (userInfo !== "admin") {
            makeToast("You are not authorized to view this page", "warn", 3);
                navigate('/', { replace: true });
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        callUserList().then(r => r);
    }, [page, pageSize,blockFilter]);

    useEffect(() => {
        if (searchTerm.length > 0) {
            setTableSkeleton(true);
            const delayDebounceFn = setTimeout(() => {
                callUserList().then(r => r);
                setTableSkeleton(false);
            }, 2000);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchTerm]);

    const handleBlockUnblock = (id) => {
        setUserId(id);
        setBlockModal(true);
    };
    const handleDelete = (id) => {
        setUserId(id);
        setOpenAlert(true);
    }
    const blockUnblockUserHandler = async () => {
        console.log("handle id", userId);
        const response = await blockUnblockUser(userId);
        if (response) {
            const newUserList = allUserList.map((el) => {
                if (el?._id === userId) {
                    el.isUserBlocked = !el.isUserBlocked;
                }
                return el;
            });
            setAllUserList(newUserList);
            const msg = response?.data?.message || "User blocked/unblocked successfully";
            makeToast(msg, "success", 3);
        } else {
            makeToast("User blocking/unblocking failed", "warn", 3);
        }
    };

    const deleteUserHandler = async () => {
        console.log("handle user id", userId);
        const response = await deleteUser({ userId: userId });
        console.log("response", response);
        if (response) {
            const newUserList = allUserList.filter((el) => el?._id !== userId);
            setAllUserList(newUserList);
            makeToast("User Deleted Successfully", "success", 3);
        } else {
            makeToast("User Not Deleted", "warn", 3);
        }
    };

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        setLoading(true);
        const response = await PostData(user,'/api/user/adminAddAdmins');
        console.log('response',response);
        if (response?.success) {
            await callUserList();
            makeToast(response?.message, "success", 3);
            handleClose();
            setLoading(false);
        } else {
            setLoading(false);
            makeToast(response?.message, "warn", 3);
        }
    };

    if (loading) { return <Loading loading={loading} /> }

    return (
        <>
            <ToastContainer/>
            <TableContainer component={Paper}>
                <Box
                    p={1}
                    sx={{
                        display: "flex",
                        justifyContent: "end",
                        borderRadius: "7px",
                        marginBottom: "5px",
                    }}
                >
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onInput={(e) => setSearchTerm(e.target.value)}
                        onPaste={(e) => setSearchTerm(e.target.value)}
                        style={{ width: "100%", marginRight: "10px" }}
                    />
                    <button
                        style={{
                            padding: "4px",  border: "none", outline: "none", fontSize: "18px", backgroundColor: "#17594A",
                            color: "white", minWidth: "100px", borderRadius: "5px", cursor: "pointer",
                        }}
                        onClick={handleOpen}
                    >
                        <Typography>Add Admin</Typography>
                    </button>
                </Box>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                             {tableHead.map((heading) => <TableCell>
                                <Typography sx={{  fontWeight: 700 }} >
                                    {heading}
                                </Typography>
                            </TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {tableSkeleton ? <TableSkeleton rowsLength={allUserList.length} circular={2} count={7} />   :( allUserList.map((user,index) => {
                            return (
                                <TableRow
                                    key={user?._id}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell align="left">
                                        <Typography>{user?._id}</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Box
                                            sx={{
                                                borderRadius: "50%",
                                                overflow: "hidden",
                                                width: "50px",
                                                height: "50px",
                                                boxShadow: "0 0 2px #888",
                                            }}
                                            onClick={() => handlePhotoClick(index)}
                                        >
                                            <img
                                                src={user?.profilePic ? user?.profilePic : person}
                                                style={{
                                                    scale: "1.1",
                                                    width: "50px",
                                                    height: "50px",
                                                    borderRadius: "50%",
                                                    objectFit: "cover",
                                                }}
                                                alt={'Ima-ge'}/>
                                        </Box>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Box>
                                            {user?.firstName} {user?.lastName}
                                        </Box>
                                    </TableCell>
                                    <TableCell align="left">{user?.email}</TableCell>
                                    <TableCell align="left">{user?.accountType}</TableCell>
                                    <TableCell align="left">
                                        <button
                                            style={{
                                                padding: "2px 5px",
                                                minWidth: "80px",
                                                border: "none",
                                                outline: "none",
                                                borderRadius: "4px",
                                                backgroundColor: user?.isUserBlocked ? '#17594A' : '#F29727' ,
                                                color: "white",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => handleBlockUnblock(user?._id)}
                                        >
                                        <Typography>
                                            { user?.isUserBlocked ? 'UnBlock' : 'Block' }
                                        </Typography>
                                        </button>
                                    </TableCell>
                                    <TableCell align="left">
                                        <button
                                            style={{
                                                padding: "2px 5px",
                                                minWidth: "90px",
                                                border: "none",
                                                outline: "none",
                                                borderRadius: "4px",
                                                backgroundColor: "#F24C3D",
                                                color: "white",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => handleDelete(user?._id)}
                                        >
                                            <Typography>
                                                Delete
                                            </Typography>
                                        </button>
                                    </TableCell>
                                </TableRow>
                            );
                        }))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={totalCounts}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                rowsPerPage={pageSize}
                onRowsPerPageChange={(e) => {
                    setPageSize(parseInt(e.target.value, 10));
                    setPage(0);
                }}
            />
            <Box>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box sx={{ display: "flex" }}>
                            <Box
                                sx={{
                                    width: "50%",
                                }}
                            >
                                <Typography>First Name</Typography>
                                <input
                                    type="text"
                                    value={user?.firstName}
                                    name="firstName"
                                    onChange={handleChange}
                                    style={{
                                        width: "90%",
                                        height: "50px",
                                        borderRadius: "7px",
                                        border: "1px solid #888",
                                        outline: "none",
                                        padding: "0px 5px",
                                        fontSize: "18px",
                                        fontWeight: "600",
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: "50%",
                                }}
                            >
                                <Typography>Last Name</Typography>
                                <input
                                    name="lastName"
                                    onChange={handleChange}
                                    value={user?.lastName}
                                    type="text"
                                    style={{
                                        width: "90%",
                                        height: "50px",
                                        borderRadius: "7px",
                                        border: "1px solid #888",
                                        outline: "none",
                                        padding: "0px 5px",
                                        fontSize: "18px",
                                        fontWeight: "600",
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: "100%" }} >
                            <Typography my={1}>Email</Typography>
                            <input
                                name="email"
                                onChange={handleChange}
                                value={user?.email}
                                type="email"
                                required
                                style={{
                                    width: "95%",
                                    height: "50px",
                                    borderRadius: "7px",
                                    border: "1px solid #888",
                                    outline: "none",
                                    padding: "0px 5px",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                }}
                            />
                        </Box>

                        <Box my={1} sx={{ display: "flex" }}>
                            <Box
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <Typography>Password</Typography>
                                <input
                                    name="password"
                                    value={user?.password}
                                    type="password"
                                    onChange={handleChange}
                                    style={{
                                        width: "90%",
                                        height: "50px",
                                        borderRadius: "7px",
                                        border: "1px solid #888",
                                        outline: "none",
                                        padding: "0px 5px",
                                        fontSize: "18px",
                                        fontWeight: "600",
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <button
                                style={{
                                    width: "95%",
                                    height: "40px",
                                    backgroundColor: "#539165",
                                    border: "none",
                                    outline: "none",
                                    borderRadius: "7px",
                                    cursor: "pointer",
                                    marginTop: "10px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "white",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                    }}
                                    onClick={handleSubmit}
                                >
                                    Create Admin
                                </Typography>
                            </button>
                        </Box>
                    </Box>
                </Modal>
            </Box>
            <Modal
                open={showModal} onClose={() => setShowModal(false)}
                aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0',
                    margin: '0',
                    height: '100vh',
                }}
            >
                <div style={{ maxWidth: '900px', width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <img
                            src={allUserList[selectedPhotoIndex]?.profilePic ? allUserList[selectedPhotoIndex]?.profilePic : person}
                            alt={'User Ki'}
                            style={{ maxWidth: '900px', minHeight:'900px'  , objectFit: 'contain' }}
                        />
                    </div>
                </div>
            </Modal>
            <AlertDialog open={openAlert} setOpen={setOpenAlert} title={'Delete User'} functionToPerform={deleteUserHandler}  />
            <AlertDialog open={blockModal} setOpen={setBlockModal} title={'Block User'} functionToPerform={blockUnblockUserHandler}  />
        </>
    );
};

export default Admins;
