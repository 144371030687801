import React, { useEffect, useState, Suspense, memo } from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import CategoryForm from "./CategoryForm";
import { useConfirm } from "material-ui-confirm";

import useApi from "../../hooks/useApi";
import Loading from "../../components/Loading";

import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";

const BlogCategory = () => {
  const notify = (msg) => toast(msg);
  const confirm = useConfirm();

  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
    get,
  } = useApi();

  const {
    data: deleteCategoryData,
    loading: deleteCategoryLoading,
    error: deleteCategoryError,
    del,
  } = useApi();

  const [isCategoryFormOpen, setIsCategoryFormOpen] = useState(false);
  const [allCategoryData, setAllCategoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});

  useEffect(() => {
    fetchCategory();
  }, []);

  const fetchCategory = () => {
    get("/api/blog/category?isAdmin=true");
  };

  //get category

  useEffect(() => {
    if (categoryLoading) {
      setIsLoading(true);
    }
    if (categoryError) {
      setIsLoading(false);
      setIsError(
        categoryError?.response?.data?.message || "internal server error"
      );
    }
    if (categoryData) {
      setIsLoading(false);
      setAllCategoryData(categoryData.data);
    }
  }, [categoryData, categoryError, categoryLoading]);

  //delete category
  useEffect(() => {
    if (deleteCategoryLoading) {
      setDeleteIsLoading(true);
    }
    if (deleteCategoryError) {
      setDeleteIsLoading(false);
      setIsError(
        categoryError?.response?.data?.message || "internal server error"
      );
    }
    if (deleteCategoryData) {
      console.log(deleteCategoryData, "deleteCategoryData");
      // notify(deleteCategoryData?.message || "Blog category deleated");
      setDeleteIsLoading(false);
      fetchCategory();
    }
  }, [deleteCategoryData, deleteCategoryError, deleteCategoryLoading]);

  const openCategoryForm = () => {
    setIsCategoryFormOpen(true);
  };

  const editCategory = (category) => {
    setIsCategoryFormOpen(true);
    setSelectedCategory(category);
  };

  const deleteCategory = (id) => {
    confirm({ description: "Are you sure you want to delete this Category?" })
      .then(() => {
        del(`/api/blog/delete-category/${id}`);
      })
      .catch((e) => {});
  };

  return (
    <>
      {isLoading && <Loading loading={isLoading} />}
      <ToastContainer />

      <Box className="w-[100%] justify-end flex">
        <Button
          variant="contained"
          onClick={openCategoryForm}
          className="gap-[5%] whitespace-nowrap"
        >
          <AddIcon /> Add Category
        </Button>
      </Box>
      {isError && <p className="error-msg-p ">{isError}</p>}

      {allCategoryData.length == 0 && !isLoading && (
        <h2>No Blog category to display</h2>
      )}

      {allCategoryData.length != 0 && (
        <table className="table-auto w-full h-max max-h-[60vh] overflow-scroll my-[2%]">
          <thead className="pop-font text-xs 2xl:text-base text-center">
            <tr className="font-[100] text-[0.95rem] text-[#696565fd] bg-slate-200">
              <th className="py-4 px-4 2xl:px-5">S.No</th>
              <th className="py-4 px-4 2xl:px-5 whitespace-nowrap">
                Category Icon
              </th>
              <th className="py-4 px-4 2xl:px-5">Category Name</th>
              <th className="py-4 px-4 2xl:px-5">Action</th>
            </tr>
          </thead>
          <tbody className="px-2 md:text-xs 2xl:text-base">
            {allCategoryData.map((ele, i) => (
              <tr
                key={i}
                className="border-b font-[100] text-[0.9rem] text-center text-[#363636]"
              >
                <td className="py-7 pl-4 pr-2 2xl:px-5">{i + 1}</td>
                <td
                  className="py-1 px-1 w-[1rem]"
                  dangerouslySetInnerHTML={{ __html: ele.image }}
                ></td>
                <td className="py-7 px-4 2xl:px-5">{ele.name}</td>
                <td>
                  <button onClick={() => editCategory(ele)}>
                    <EditIcon />
                  </button>
                  <button onClick={() => deleteCategory(ele._id)}>
                    {!deleteIsLoading ? (
                      <DeleteIcon />
                    ) : (
                      <ClipLoader size={5} color="gray" />
                    )}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {isCategoryFormOpen && (
        <Modal
          open={isCategoryFormOpen}
          onClose={() => {
            setIsCategoryFormOpen(false);
            setSelectedCategory({});
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="flex justify-center items-center"
        >
          <CategoryForm
            close={() => setIsCategoryFormOpen(false)}
            selectedCategory={selectedCategory}
            fetchCategory={fetchCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </Modal>
      )}
    </>
  );
};

export default memo(BlogCategory);
