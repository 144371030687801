import React, { useEffect, useState,memo } from "react";
import { TextField, Button, Box } from "@mui/material";

import useApi from "../../hooks/useApi";
import Loading from "../../components/Loading";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";

const CategoryForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  // State to hold the form data
  const [formData, setFormData] = useState({
    categoryName: "",
    categoryIcon: "",
    categoryId: "",
  });

  useEffect(() => {
      setFormData({
        categoryName: props?.selectedCategory?.name || "",
        categoryIcon: props?.selectedCategory?.image || "",
        categoryId: props?.selectedCategory?._id || "",
      });
  }, [props]);

  const { data, loading, error, post } = useApi();

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
      return;
    }
    if (error) {
      setIsError(error?.response?.data?.message || "internal server error");
      setIsLoading(false);
      return;
    }
    if (data) {
      toast(data.message);

      setTimeout(() => {
        props.close();
        props.fetchCategory();
        setIsLoading(false);
      }, 3000);
       props.setSelectedCategory({})
      return;
    }
  }, [data, loading, error]);

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle submission logic here (e.g., API call or state update)

    const data = {
      name: formData.categoryName,
      image: formData.categoryIcon,
      blogCategoryId: formData.categoryId,
    };
    post("/api/blog/save-category", data, {});
  };

  return (
    <>
      <ToastContainer />



      <Box className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-lg !w-[50%]">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Add New Category
        </h2>
        <form onSubmit={handleSubmit} className="flex flex-col gap-[1rem]">
          <TextField
            label="Category Name"
            variant="outlined"
            name="categoryName"
            value={formData.categoryName}
            onChange={handleInputChange}
            fullWidth
            required
            className="mb-4"
          />

          <TextField
            label="Category Icon"
            variant="outlined"
            name="categoryIcon"
            value={formData.categoryIcon}
            onChange={handleInputChange}
            fullWidth
            required
            className="mb-4"
          />

          {!isLoading ? (
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          ) : (
            <Button variant="contained" color="primary" fullWidth>
              <ClipLoader size={20} color="#fff" />
              Please Wait...{" "}
            </Button>
          )}

          {isError && <p className="error-msg-p ">{isError}</p>}
        </form>
      </Box>
    </>
  );
};

export default memo(CategoryForm);
