import React, { useState, useEffect } from "react";
import { getAllReports } from "../../api/api";
import {
  Box,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Modal
} from "@mui/material";
import useMakeToast from "../../hooks/makeToast";
import { deletePost, deleteReport } from "../../api/api";
import Loading from "../../components/Loading";
import {ToastContainer} from "react-toastify";
import Slider from "react-slick";
import FilterDropDown from "../../components/filterDropDown";
import AlertDialog from "../../components/AlertDialog";
import {tableCountShow, tableText} from "../../components/miniComponents";
import TableSkeleton from "../../components/TableSkeleton";
const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
  {
    id: "population",
    label: "Population",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Size\u00a0(km\u00b2)",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "Density",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

const settings = {
  dots: true,
  infinite: false ,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];

function ReportPage() { 
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCounts, setTotalCounts] = useState(1);
  const [allReports, setAllReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const makeToast = useMakeToast();
  const [searchTerm, setSearchTerm] = useState("");

  //images
  const [showModal, setShowModal] = useState(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const handlePhotoClick = (index) => {
    setSelectedPhotoIndex(index);
    console.log('index',index,'selectedPhotoIndex File');
    setShowModal(true);
  };
  const  handleSearch = async (e) => {
    setSearchTerm(e.target.value);
    // await fetchAllReports();
  }
  const fetchAllReports = async () => {
    const response = await getAllReports(page,pageSize,searchTerm,{'reporterType':reporterType});
    if (response) {
      const postReport = response?.data?.data.filter(
        (el) => el?.label === "post"
      );
      setAllReports(postReport);
      setTotalCounts(response?.data?.totalReports);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const [openAlert, setOpenAlert] = useState(false);
  const [postId, setPostId] = useState(null);
  const [listId, setListId] = useState(null);
  const [reporterType, setReporterType] = useState(null);
  const optionsReporters = [
    { value: null, label: "Reporter Type" },
    { value: 'seller', label: 'Seller' },
    { value: 'consultant', label: 'Consultant' },
    { value: 'supplier', label: 'Supplier' },
    ];
  const tableHead = ['Type','Post Image','Heading','Description','Post Text','Post Likes','Post Comments','Reporter Name','Reporter Email',<FilterDropDown options={optionsReporters} value={reporterType} setValue={setReporterType} />,'Delete Post'];
  const [tableSkeleton,setTableSkeleton] = useState(true);
  const handleDelete = (id,list) => {
    setPostId(id);
    setListId(list);
    setOpenAlert(true);
  }
  useEffect(() => {
    fetchAllReports().then(() =>  setTableSkeleton(false));
  }, [page,pageSize,reporterType]);

  useEffect(() => {
    if (searchTerm !== "") {
      setTableSkeleton(true);
      fetchAllReports().then(() =>  setTableSkeleton(false))
    }
  }, [searchTerm]);
  const deleteUserHandler = async () => {
    // console.log("deleteUserHandler", postId, listId);
    // return;
    await deleteReport({ listId });
    const response = await deletePost(postId);
    if (response) {
      const newPostList = allReports.filter((el) => el?._id !== listId);
      setAllReports(newPostList);
      makeToast("Post deleted successfully", "success", 3);
    } else {
      makeToast("Post deleted Not Deleted", "warn", 3);
    }
  };
  if (loading) {
    return (
      <>
        <Loading loading={loading} />
      </>
    );
  };
  return (
    <>
      <ToastContainer/>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {/* sx={{ maxHeight: 440 }} */}
        <TableContainer> 
          <Box
              p={1}
              sx={{
                display: "flex"
              }}
          >
            <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onInput={handleSearch}
                sx={{ width: "100%" }}
            />
          </Box>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableHead.map((_,idx) =>
                <TableCell key={idx}>
                  <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 600,
                      }}
                  >
                    {_}
                  </Typography>
                </TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableSkeleton ? <TableSkeleton rowsLength={allReports.length} circular={2} count={11}/>  : ( allReports.length > 0 ?
                allReports.map((row,index) => {
                  const reporterName = `${row?.reporterId?.firstName} ${row?.reporterId?.lastName}`;
                  return (
                    <TableRow hover tabIndex={-1} key={row?._id}>
                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            {row.label}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell align="left">
                        <Box
                          sx={{
                            borderRadius: "7px",
                            overflow: "hidden",
                            width: "50px",
                            height: "50px",
                          }}
                          onClick = { () => handlePhotoClick(index) }
                        >
                          <img
                            src={
                              row?.scammerId?.file[0]['fileKey']
                            }
                            width="50px"
                            height="50px"
                            style={{
                              // borderRadius: "50%",
                              objectFit: "cover",
                              border: "1px solid gray",
                              width: "50px",
                            }}
                           alt={'Post'}/>
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            {row?.heading}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            {row?.description}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {tableText(row?.scammerId?.text ,'No Text')}
                        </Box>
                      </TableCell>
                      <TableCell component="th" scope="row">
                            {tableCountShow(row?.scammerId?.likes?.length)}
                      </TableCell>

                      <TableCell component="th" scope="row">
                            {tableCountShow(row?.scammerId?.comments?.length)}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {tableText(reporterName)}
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                            {tableText( row?.reporterId?.email)}
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >{tableText( row?.reporterId?.accountType)}</Box>
                      </TableCell>

                      <TableCell align="left">
                        <button
                          style={{
                            padding: "5px",
                            minWidth: "80px",
                            border: "none",
                            outline: "none",
                            borderRadius: "4px",
                            backgroundColor: "#F24C3D",
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={() =>  handleDelete(row?.scammerId?._id, row?._id) }
                        >
                          Delete
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                }):<TableRow>
                    <TableCell colSpan={11} align="center">
                      <Typography>No Data Found</Typography>
                    </TableCell>
                  </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
            component="div"
            count={totalCounts}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => {
              setPageSize(parseInt(e.target.value, 10));
              setPage(0);
            }}
        />
      </Paper>
      {allReports.length === 0 ? (
        <Box
          sx={{
            minHeight: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",  
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "600",
              color: "#888888",
            }}
          >
            There in no Record Available At this Moment
          </Typography>
        </Box>
      ) : (
        ""
      )}
      <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0',
            margin: '0',
            height: '100vh',
          }}
      >
        <div className="slider-container" style={{ maxWidth: '900px', width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
          <Slider {...settings} style={{ width: '100%', height: '100%' }}>
            {allReports[selectedPhotoIndex]?.scammerId?.file.map((file, index) => (
                <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  {file.type === 'image' ? (
                      <img
                          src={ file.fileKey }
                          alt={`Photo ${index}`}
                          style={{ maxWidth: '900px', minHeight:'900px' , objectFit: 'contain' }}
                      />
                  ) : (
                      <video
                          src={file?.fileKey}
                          autoPlay
                          loop
                          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                      />
                  )}
                </div>
            ))}
          </Slider>
        </div>
      </Modal>
      <AlertDialog open={openAlert} setOpen={setOpenAlert} title={'Delete Reported Post'} functionToPerform={deleteUserHandler}  />

    </>
  );
}

export default ReportPage;
