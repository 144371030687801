import React, {useEffect, useRef, useState} from "react";
import FilterDropDown from "../../components/filterDropDown";
import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    OutlinedInput,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import Slider from 'react-slick';
import useMakeToast from "../../hooks/makeToast";
import Loading from "../../components/Loading";
import {createPost, deletePost, URL,} from "../../api/api";
import post from "../../assets/svg/post.svg";
import uploadImg from "../../assets/svg/upload.png";
import axios from "axios";
import {ToastContainer} from "react-toastify";
import AlertDialog from "../../components/AlertDialog";
import {tableCountShow, tableText} from "../../components/miniComponents";
import TableSkeleton from "../../components/TableSkeleton";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "#A2X",
  "#Acquire",
  "#Adobe Commerce",
  "#Unicorn Smasher",
  "#Veeqo",
  "#Viral",
  "#Analytics",
  "#Launch",
  "#Walmart",
  "#Launch",
  "#Wayfair",
  "#Whatagraph",
  "#Wix",
  "#WooCommerce",
  "#WordPress",
  "#Yotpo",
  "#Zalando",
  "#Zapier",
  "#Zendesk",
  "#Zoho",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #888",
  boxShadow: 24,
  p: 2,
  borderRadius: "7px",
};

const PostPage = () => {
  const postList = useSelector((state) => state?.allPostDetails);
  const [loading, setLoading] = useState(true);
  const [allPostListArr, setAllPostListArr] = useState([]);
  const [privacyStatus, setPrivacyStatus] = useState("anyone");
  const [open, setOpen] = useState(false);
  const [baseImage, setBaseImage] = useState([]);
  const [fileType, setFileType] = useState("");
  const [textParagraph, setTextParagraph] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCounts, setTotalCounts] = useState(1);
  const [userImg, setUserImg] = useState({});
  const handleOpen = () => setOpen(true);
  const modalRef = useRef(null);

  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const dispatch = useDispatch();
  const makeToast = useMakeToast();
  const [preview, setPreview] = useState(false);
  const fileRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");

  //images
    const [showModal, setShowModal] = useState(false);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
    const [openAlert, setOpenAlert] = useState(false);
    const [postId, setPostId] = useState(null);
    const [postPrivacyStatus, setPostPrivacyStatus] = useState(null);
    const [tableSkeleton, setTableSkeleton] = useState(false);
    const optionsPostPrivacyStatus = [
        {value:null, label:'Privacy Status'},
        {value:'public', label:'Public'},
        {value:'private', label:'Private'},
    ];
    const handleDelete = (id) => {
        setPostId(id);
        setOpenAlert(true);
    }
    const handlePhotoClick = (index) => {
        setSelectedPhotoIndex(index);
        console.log('index',index,'selectedPhotoIndex File',allPostListArr[selectedPhotoIndex].file);
        setShowModal(true);
    };
    const  handleSearch = async (e) => {
        setSearchTerm(e.target.value);
    }

    //pagination

  const callPostList = async () => {
      let CallUrl = `${URL}/api/post/getAllPostsAdmin?page=${page}&limit=${pageSize}&filters=${JSON.stringify({
          'postPrivacyStatus':postPrivacyStatus}
      )}`;
      if (searchTerm != null) {
            CallUrl = `${URL}/api/post/getAllPostsAdmin?page=${page}&limit=${pageSize}&search=${searchTerm}&filters=${JSON.stringify({
                'postPrivacyStatus':postPrivacyStatus}
            )}`;
      }
    const response = await axios.get(CallUrl);
    console.log(response,"over all response =-=-=");
    // dispatch(response);
    if (response) {
    console.log(response?.data?.allPosts,"response for data ==-==--=");

    setAllPostListArr(response?.data?.allPosts);
    setTotalCounts(response?.data?.totalPostsCount);
    // dispatch(allPostDetails(response?.data?.allPosts));
    setLoading(false);
    }
  };

    const settings = {
        dots: true,
        infinite: false ,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

  useEffect(() => {
      setLoading(true);
        callPostList().then(() => setLoading(false));
  }, [ page, pageSize,postPrivacyStatus]);

      useEffect(() => {
        if (searchTerm !== "") {
            setTableSkeleton(true);
            callPostList().then(() => setTableSkeleton(false));
        }
    }, [searchTerm]);


  const deleteUserHandler = async () => {
    console.log("handle  id", postId);
    const response = await deletePost(postId);
    if (response) {
      const newPostList = allPostListArr.filter((el) => el?._id !== postId);
      setAllPostListArr(newPostList);
      makeToast("Post deleted successfully", "success", 3);
    } else {
      makeToast("Post deleted Not Deleted", "warn", 3);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const ImageHandle = async (event) => {
    let file = event.target.files[0];
    let type = "";
    console.log("file", file);
    // let files = file.length > 0;
    if (event.target.files && file.name.match(/\.(jpg|jpeg|png|gif|mp4)$/)) {
      if (file?.type === "video/mp4") {
        setFileType("video");
        type = "video";
      } else {
        type = "image";
        setFileType("image");
      }

      setPreview(URL.createObjectURL(file));
      const base64Img = await toBase64(file);

      setBaseImage({ file: base64Img, type });
    } else {
      makeToast("Please Select Only Image", "warn", 3);
      fileRef.current.value = null;
      return false;
    }
  };

  const tableHead = ['Profile Image','Text','Author Name','Total BookMarks','Total Likes','Total Comments','Total HashTags',                    <FilterDropDown options={optionsPostPrivacyStatus} value={postPrivacyStatus} setValue={setPostPrivacyStatus} />,'Post Delete']
  const handleMultipleSelect = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value );
  };

  const handleChangeRole = (e) => setPrivacyStatus(e?.target?.value);

  const handleSubmit = async () => {
    setLoading(true);
    console.log("fileType===>", fileType);
    const response = await createPost({
      hashtags: personName,
      file: [baseImage],
      privacyStatus,
      text: textParagraph,
      fileType: fileType ? fileType : "text",
    });
    if (response?.data?.success) {
      makeToast(response?.data?.message, "success", 3);
      setAllPostListArr([response?.data?.post, ...allPostListArr]);
      setLoading(false);
      setTextParagraph("");
      setBaseImage([]);
      setFileType("");
      setPreview(false);
      handleClose();
      setPersonName([]);
    } else {
      makeToast("Please Provide all Fields", "warn", 3);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <>
        <Loading loading={loading} />
      </>
    );
  }

  return (
    <>
    <ToastContainer />
      {/* sx={{ maxHeight: 500 }} */}
      <TableContainer component={Paper}>
          <Box
              p={1}
              sx={{
                  display: "flex",
                  justifyContent: "end",
                  borderRadius: "7px",
                  marginBottom: "5px",
              }}
          >
              <TextField
                  label="Search"
                  variant="outlined"
                  value={searchTerm}
                  onInput={handleSearch}
                  style={{ width: "100%", marginRight: "10px" }}
              />

              <button
                  style={{
                      padding: "4px 10px",
                      border: "none",
                      outline: "none",
                      fontSize: "18px",
                      backgroundColor: "#17594A",
                      color: "white",
                      minWidth: "100px",
                      borderRadius: "5px",
                      cursor: "pointer",
                  }}
                  onClick={handleOpen}
              >
                  <Typography>Add Admin Post</Typography>
              </button>
          </Box>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
                {tableHead.map((heading) =>
                    <TableCell>
                    <Typography sx={{fontWeight: 700 }}>
                        {heading}
                    </Typography>
                </TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableSkeleton ?  <TableSkeleton rowsLength={allPostListArr.length} circular={1} count={9} /> :
            (allPostListArr.length > 0 ? allPostListArr?.map(({
              _id,
              author,
              bookmarks,
              comments,
              file,
              hashtags,
              likes,
              privacyStatus: privacyStatus1,
              text
              }, index) => {
                const type = file[0]?.type ? file[0]?.type : null;
                const fileKey = file[0]?.fileKey ? file[0]?.fileKey : null;
                const authorName = author?.firstName + " " + author?.lastName;
                return (
                <TableRow
                  key={_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    <Box
                      sx={{
                        overflow: "hidden",
                        width: "50px",
                        height: "50px",
                      }}
                      onClick={() => handlePhotoClick(index)}
                    >
                      {file.length === 0 ||
                      type === "image" ? (
                          <img
                            src={ file && fileKey ? fileKey : post }
                            width="50px"
                            height="50px" alt="img"
                            style={{
                              borderRadius: "50%",
                              objectFit: "cover",
                              border: "1px solid gray",
                              width: "50px",
                            }}
                          />
                      ) : (
                            <video width="50" height="50" controls>
                                <source src={file ? fileKey : undefined} type="video/mp4" />
                                <source src={file ? fileKey : undefined} type="video/webm" />
                                Your browser does not support the video tag.
                            </video>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Box
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100px",
                        maxHeight: "100px",
                        margin: "auto"
                      }}
                    >
                        {tableText(text,'Not Provided')}
                    </Box>
                  </TableCell>
                  <TableCell align="left">{tableText(authorName,'Not Provided')}</TableCell>
                    <TableCell component="th" scope="row">{tableCountShow(bookmarks.length)}</TableCell>
                  <TableCell align="left">{tableCountShow(likes.length)}</TableCell>
                  <TableCell align="left">{tableCountShow(comments.length)}</TableCell>
                  <TableCell align="left">{tableCountShow(hashtags.length)}</TableCell>
                  <TableCell align="left">{privacyStatus1}</TableCell>
                  <TableCell align="left">
                    <button
                      style={{
                        padding: "5px",
                        minWidth: "80px",
                        border: "none",
                        outline: "none",
                        borderRadius: "4px",
                        backgroundColor: "#F24C3D",
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDelete(_id)}
                    >
                      Post Delete
                    </button>
                  </TableCell>
                </TableRow>
              );
            }) : (
                <TableRow>
                    <TableCell colSpan={9} align="center">
                        <Typography>No Post Found</Typography>
                    </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        <TablePagination
            component="div"
            count={totalCounts}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => {
                setPageSize(parseInt(e.target.value, 10));
                setPage(0);
            }}
        />
      <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
      </Box>

      {/* modal create here */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              cursor: "pointer",
              height: "140px",
              width: "100%",
              backgroundColor: "#EDF1D6",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
            onClick={() => fileRef.current.click()}
          >
            {preview ? (
              <>
                <img
                  src={preview}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                 alt={'preview'}/>
              </>
            ) : (
              <>
                <img src={uploadImg}  alt={'preview'} />
              </>
            )}

            <input
              hidden
              ref={fileRef}
              type="file"
              //   style={{ display: "none" }}
              accept="image/png, image/gif, image/jpeg, video/mp4"
              onChange={ImageHandle}
            />
          </Box>
          <Box>
            <Typography
              sx={{

                marginTop: "10px",
              }}
            >
              Write Text Here
            </Typography>
            <textarea
              value={textParagraph}
              onChange={(e) => setTextParagraph(e.target.value)}
              style={{
                margin: "10px 0px",
                resize: "none",
                width: "calc(100% - 0px)",
                minHeight: "120px",
                borderRadius: "7px",
                padding: "5px",
                fontSize: "16px",
                outline: "none",
                border: "1px solid #888888",
              }}
            />
          </Box>
          <Box>
            {/* <Typography
              sx={{

                // marginTop: "10px",
              }}
            >
              Privacy Status
            </Typography> */}

            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-multiple-name-label">Hashtags</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={personName}
                onChange={handleMultipleSelect}
                input={<OutlinedInput label="Hashtags" />}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box>
            <FormControl sx={{ mt: 3, width: "100%", height: "50px" }}>
              <InputLabel id="demo-simple-select-helper-label">
                Privacy Status
              </InputLabel>
              <Select
                sx={{
                  height: "50px",
                  // border: "none",
                  outline: "none",
                  borderRadius: "7px",
                  // border: "1px solid #888",
                }}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={privacyStatus}
                label="Privacy Status"
                onChange={handleChangeRole}
              >
                <MenuItem value="onlyme">OnlyMe</MenuItem>
                <MenuItem value="anyone">anyOne</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <button
              style={{
                width: "100%",
                height: "40px",
                backgroundColor: "#539165",
                border: "none",
                outline: "none",
                borderRadius: "7px",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                onClick={handleSubmit}
              >
                Submit Post
              </Typography>
            </button>
          </Box>
        </Box>
      </Modal>
        <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0',
                margin: '0',
                height: '100vh',
            }}
        >
            <div className="slider-container" style={{ maxWidth: '900px', width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <Slider {...settings} style={{ width: '100%', height: '100%' }}>
                    {allPostListArr[selectedPhotoIndex]?.file.map((file, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            {file.type === 'image' ? (
                                <img
                                    src={file?.fileKey}
                                    alt={`Photo ${index}`}
                                    style={{ maxWidth: '900px', minHeight:'900px' , objectFit: 'contain' }}
                                />
                            ) : (
                                <video
                                    src={file?.fileKey}
                                    autoPlay
                                    loop
                                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                                />
                            )}
                        </div>
                    ))}
                </Slider>
            </div>
        </Modal>
        <AlertDialog open={openAlert} setOpen={setOpenAlert} title={'Delete Post'} functionToPerform={deleteUserHandler}  />

    </>
  );
};

export default PostPage;
