import axios from "axios";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export const loginHandler = async (postData) => {
  try {
    const response = await axios.post(`${apiUrl}/api/auth/web/login`, postData, {
      header: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(err)
    throw err;
  }
};

export const fetchNewAccessToken = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/auth/web/refresh`, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const logoutHandler = async () => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/auth/web/logout`,
      {},
      {
        withCredentials: true,
      }
    );
    return true;
  } catch (err) {
    throw err;
  }
};

// export const resetPasswordHandler = async (postData) => {
//   try {
//     const response = await axios.post(
//       `${apiUrl}/api/auth/reset-password`,
//       postData,
//       {
//         headers: {
//           "Content-type": "application/json",
//         },
//         withCredentials: true,
//       }
//     );
//     return response;
//   } catch (err) {
//     throw err;
//   }
// };

// export const setPasswordHandler = async (postData) => {
//   try {
//     const response = await axios.post(`${apiUrl}/api/auth/set-password`, postData, {
//       headers: {
//         "Content-type": "application/json",
//       },
//       withCredentials: true,
//     });
//     return response;
//   } catch (err) {
//     throw err;
//   }
// };
