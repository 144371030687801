import React, { useEffect, useState } from "react";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import styles from "../blogs/allBlog.module.css";

import { useNavigate } from "react-router-dom";

import useApi from "../../hooks/useApi";

import DeleteIcon from "@mui/icons-material/Delete";

import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Loading from "../../components/Loading";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export default function NewsLetterList() {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [newsLetterData, setNewsLetterData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(5);

  const { data, loading, error, get } = useApi();
  const {
    data: csvData,
    loading: csvLoading,
    error: csvError,
    get: csvGet,
  } = useApi();

  useEffect(() => {
    getNewsLetterData(1);
  }, []);

  const getNewsLetterData = (page) => {
    get(`/api/newsletter/subscribes?limit=10&page=${page}`);
  };

  //get newLetter data

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
      return;
    }
    if (error) {
      setIsError(error?.response?.data?.message || "internal server error");
      setIsLoading(false);
      return;
    }

    if (data) {
      setIsLoading(false);
      setNewsLetterData(data.data);
      setTotalData(Math.ceil(data.totalCount / 10));
      console.log(data);
      return;
    }
  }, [data, loading, error]);

  //get csv file
  useEffect(() => {
    if (csvLoading) {
      setIsLoading(true);
      return;
    }
    if (csvError) {
      setIsError(csvError.response.data.message);
      setIsLoading(false);
      return;
    }
    if (csvData) {
      setIsLoading(false);
      // setNewsLetterData(csvData.data);
      downloadFile(csvData, "newsletter_subscribers");

      return;
    }
  }, [csvData, csvLoading, csvError]);

  const downloadCsv = async () => {
    // csvGet("/api/newsletter/export-subscribers");
    try {
      const response = await axios.get(`${apiUrl}/api/newsletter/export-subscribers`, {
        responseType: "blob", // Important for handling binary data
      });
      downloadFile(response.data, "subscribers");
    } catch (err) {
      console.log(err);
    }
  };

  const downloadFile = (data, filename) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const formatTimestamp = (timestamp) => {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp);

    // Define arrays to get month and day names
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Get the components of the date
    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    // Determine AM or PM suffix
    const ampm = hours >= 12 ? "pm" : "am";

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format minutes to be two digits
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    // Construct the formatted date string
    const formattedDate = `${month} ${day} ${year}, ${hours}:${formattedMinutes} ${ampm}`;

    return formattedDate;
  };

  const handlePageChange = (event, value) => {
    getNewsLetterData(value);
    setPage(value);
  };

    if (isLoading) {
      return (
        <>
          <Loading loading={isLoading} />
        </>
      );
    }

  return (
    <>
      <ToastContainer />

      {isError && <p className="error-msg-p ">{isError}</p>}

      <>
        <h1 className={styles["website-query-heading"]}>Subscribers</h1>
        {newsLetterData.length == 0 && <h2>No Data to display</h2>}
        <div className={styles["download-btn-wrapper"]}>

        <Button
          variant="contained"
          endIcon={<FileDownloadIcon />}
          onClick={() => downloadCsv()}
        >
          Export to Excel 
        </Button>
        </div>

        {newsLetterData.length != 0 && (
          <table className={`table-auto w-full`}>
            <thead
              className={`${styles.tableHeader} pop-font text-xs 2xl:text-base text-left`}
            >
              <tr className="font-semibold">
                <th className="md:font-medium py-4 px-4 2xl:px-5">S.No</th>
                <th className="md:font-medium py-4 px-4 2xl:px-5">Email</th>

                <th className="md:font-medium py-4 px-4 2xl:px-5">Date</th>
              </tr>
            </thead>
            <tbody className="px-2 md:text-xs 2xl:text-base">
              {newsLetterData.map((ele, index) => {
                return (
                  <tr key={index} className="border-b">
                    <td
                      className={`py-7 pl-4 pr-2 2xl:px-5 font-bold text-black`}
                    >
                      {/* {page - 1 != 0 && page - 1} {index + 1} */}
                      {(page - 1) * 10 + (index + 1)}
                    </td>

                    <td className={`${styles.tableData} py-7 px-4 2xl:px-5`}>
                      {ele?.email}
                    </td>

                    <td className={`${styles.tableData} py-7 px-4 2xl:px-5`}>
                      {formatTimestamp(ele.createdAt).split(",")[0]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <Stack spacing={2} className={styles["pagination-wrapper"]}>
          <Pagination
            count={totalData}
            color="primary"
            onChange={handlePageChange}
            page={page}
          />
        </Stack>
      </>
    </>
  );
}
