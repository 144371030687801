import React, { useEffect, useState } from "react";
import styles from "./blogPreview.module.css";

import { Link } from "react-router-dom";


import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";


  const formatTimestamp = (timestamp) => {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp);

    // Define arrays to get month and day names
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Get the components of the date
    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    // Determine AM or PM suffix
    const ampm = hours >= 12 ? "pm" : "am";

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format minutes to be two digits
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    // Construct the formatted date string
    const formattedDate = `${month} ${day} ${year}, ${hours}:${formattedMinutes} ${ampm}`;

    return formattedDate;
  };

const BlogContentComponent = (props) => {
  const [blogData, setBlogData] = useState({});

  useEffect(() => {
    setBlogData(props.blogData);
  }, [props.blogData]);
  return (
    <>
      {blogData && Object.keys(blogData).length > 0 ? (
        <section className={styles["blog-content-section"]}>
          <div className={styles["back-btn-wrapper"]}>
            <span
              className={`${styles["back-btn"]} arrow-btn-circle`}
              onClick={() => props.setOpenPreview(false)}
            >
              <KeyboardArrowLeftIcon className="arrow-btn" />
            </span>
          </div>
          <p className={styles["blog-date"]}>
            {formatTimestamp(blogData.updatedAt).split(",")[0]}
          </p>
          <h1 className={styles["blog-heading"]}>{blogData.title}</h1>
          <div className={styles["blog-img-wrapper"]}>
            <img src={blogData.bannerImg.src} alt="banner-img" />
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: blogData.content,
            }}
            className={`${styles["blog-content-wrapper"]} ql-editor`}
          ></div>
        </section>
      ) : (
        <div className="center-loader-div">No Data Yet</div>
      )}
    </>
  );
};

export default BlogContentComponent;
