import React from "react";

import { Outlet } from "react-router-dom";

import NewsLetterHeader from "./newsLetterHeader";

const Index = () => {
  return (
    <>
      <NewsLetterHeader />

      <Outlet />
    </>
  );
};

export default Index;
