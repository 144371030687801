import React, { useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import {Button} from "@mui/material";

const BarchartUserSignedUp = ({ users }) => {
    const [selectedPeriod, setSelectedPeriod] = useState('24h');
    const data = selectedPeriod === '24h' ? users.countLast24Hours :
        selectedPeriod === 'week' ?
            users.countLastWeek : users.countLastMonth;
    const xAxisData = ['Users'];
    const seriesData = [{ data: [data] }];

    const handlePeriodChange = (period) => {
        setSelectedPeriod(period);
    };

    return (
        <div style={{marginBottom: '50px'}}>
            <h3>Number of Users Signed Up</h3>
            <BarChart
                xAxis={[{ scaleType: 'band', data: xAxisData }]}
                series={seriesData}
                width={800}
                height={400}
            />
            <div  style={{ display: 'flex', gap: '10px', textAlign: 'center' , justifyContent: 'center' , marginTop: '10px' }}>
                <Button
                    onClick={() => handlePeriodChange('24h')}
                    variant={selectedPeriod === '24h' ? 'contained' : 'outlined'}
                    color="primary"
                >
                    Last 24 Hours
                </Button>
                <Button
                    onClick={() => handlePeriodChange('week')}
                    variant={selectedPeriod === 'week' ? 'contained' : 'outlined'}
                    color="primary"
                >
                    Last Week
                </Button>
                <Button
                    onClick={() => handlePeriodChange('month')}
                    variant={selectedPeriod === 'month' ? 'contained' : 'outlined'}
                    color="primary"
                >
                    Last Month
                </Button>
            </div>
        </div>
    );
};

export default BarchartUserSignedUp;
