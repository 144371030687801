import React, { useState } from "react";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import BookIcon from "@mui/icons-material/Book";
import SaveAsIcon from "@mui/icons-material/SaveAs";

const BlogsHeader = () => {
  const [headingName,setHeadingName]=useState("Blogs List")

  const changeHeadingValue=(name)=>{
   setHeadingName(name)
  }

  const blogActions = [
    {
      route: "./",
      icon: <BookIcon />,
      name: "View Blog",
    },
    {
      route: "./create-blog",
      icon: <SaveAsIcon />,
      name: "Create Blog",
    },
    {
      route: "./blog-category",
      icon: <SaveAsIcon />,
      name: "Blog Category",
    },
  ];
  return (
    <>
      <div className="bg-[#efecec] p-[1%] sticky top-0 z-[3] mb-[3%]">
        <h2 className="font-[700] text-[1.8rem] font-heading py-[1%]  pt-0">
          {headingName}
        </h2>
        <Stack direction="row" spacing={2}>
          {blogActions.map((ele)=>{
            return(


          <Link to={ele.route} className="">
            <Button
              variant="contained"
              onClick={() => changeHeadingValue(ele.name)}
              className="gap-[5%] whitespace-nowrap"
            >
              {ele.icon}
             {ele.name}
            </Button>
          </Link>
            )
          })}
          
        </Stack>
      </div>
    </>
  );
};


export default BlogsHeader;
