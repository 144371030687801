import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
// import { PostData } from "../../api/api";
import useMakeToast from "../../hooks/makeToast";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../components/Loading";
import axios from "axios";

const UploadCsvModal = ({ openCsv, setCsvOpen }) => {
  const [csvFile, setCsvFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName,setFileName]=useState('            Upload file')
  const makeToast = useMakeToast();
  // makeToast('Users failed to upload', 'info');
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name)
    console.log("file", file);
    setCsvFile(file);
  };

  const handleUpload = async () => {
    if (csvFile) {
      // const reader = new FileReader();
      // reader.onload = async (e) => {
      //     const text = e.target.result;
      //     const rows = text.split('\n').map(row => row.split(','));
      //     const headers = rows[0];
      //     const data = rows.slice(1).map(row => {
      //         const obj = {};
      //         headers.forEach((header, index) => {
      //             obj[header.trim()] = row[index].trim();
      //         });
      //         return obj;
      //     });
      //         console.log('data =>',data);
      const formData = new FormData(); // Create a new FormData object
      formData.append("file", csvFile);

      setIsLoading(true);
      try {
        const accessToken = localStorage.getItem("accessToken");

        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/upload/add-user-by-excel`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Set the content type
              Authorization: `Bearer ${accessToken}`, // Include the JWT in the Authorization header
            },
          }
        );
        makeToast("CSV file uploaded successfully", "success");
      } catch (error) {
        console.log(error);
        makeToast(error.message, "error");
      } finally {
        setIsLoading(false);
      }

      // reader.readAsText(csvFile);
      setCsvOpen(false);
    }
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  return (
    <>
      {isLoading && <Loading loading={isLoading} />}

      <ToastContainer />
      <Modal
        open={openCsv}
        onClose={() => setCsvOpen(false)}
        aria-labelledby="upload-csv-modal-title"
        aria-describedby="upload-csv-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            alignItems: "center",
            p: 4,
          }}
          style={{ textAlign: "center" }}
        >
          <Typography variant="h6" id="upload-csv-modal-title" gutterBottom>
            Upload Excel File
          </Typography>
          <Button
            component="label"
            role={undefined}
            variant={"contained"}
            tabIndex={-1}
            style={{
              marginBottom: "20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            startIcon={<CloudUploadIcon />}
          >
            {fileName}
            <VisuallyHiddenInput
              onChange={(e) => handleFileChange(e)}
              type="file"
            />
          </Button>
          <Box display="flex" justifyContent="space-between">
            <Button
              onClick={() => setCsvOpen(false)}
              color="secondary"
              variant="contained"
            >
              Cancel
            </Button>
            <Button onClick={handleUpload} color="primary" variant="contained">
              Upload
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UploadCsvModal;
