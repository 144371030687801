import { Box, Button, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import ReportIcon from "@mui/icons-material/Report";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import GavelIcon from "@mui/icons-material/Gavel";
import PolicyIcon from "@mui/icons-material/Policy";
import VerifiedIcon from "@mui/icons-material/Verified";
import {  useSelector } from "react-redux";

import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import BookIcon from "@mui/icons-material/Book";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CookieIcon from "@mui/icons-material/Cookie";

import CommentsDisabledIcon from "@mui/icons-material/CommentsDisabled";

const Headers = () => {
  const [shortNav, setShortNav] = useState(false);
  const userInfo = useSelector((state) => state?.users?.userInfo?.accountType);

  if (!shortNav) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "space-between",
            height: "100%",
            background: "#69C3F9",
          }}
        >
          <Box
            sx={{
              height: "100%",
              minWidth: "80px",
              background: "#69C3F9",
              boxShadow: "0 0 8px rgba(0,0,0,0.1)",
              borderRadius: "7px",
              display: "flex",
              flexDirection: "column",
              overflow: "scroll",
            }}
            className="icon-wrapper"
          >
            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                fontSize: "18px",
                cursor: "pointer",
              }}
              onClick={() => setShortNav(true)}
            >
              <MenuIcon fontSize="large" />
            </Box>

            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <NavLink
                to="/"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#EB3A18" : "#fff",
                
                })}
              >
                <DashboardIcon fontSize="large" />
              </NavLink>
            </Box>


            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <NavLink
                to="/user"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#EB3A18" : "#fff",
               
                })}
              >
                <PersonIcon fontSize="large" />
              </NavLink>
            </Box>
            {userInfo === "admin" ? (
              <>
                <Box
                  mb={1}
                  sx={{
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <NavLink
                    to="/admin-lists"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "#EB3A18" : "#fff",
                    
                    })}
                  >
                    <SupervisorAccountIcon fontSize="large" />
                  </NavLink>
                </Box>
                <Box
                  mb={1}
                  sx={{
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <NavLink
                    to="/activities"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "#EB3A18" : "#fff",
                      
                    })}
                  >
                    <RemoveRedEyeIcon fontSize="large" />
                  </NavLink>
                </Box>
              </>
            ) : null}

            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <NavLink
                to="/post"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#EB3A18" : "#fff",
                  
                })}
              >
                <WysiwygIcon fontSize="large" />
              </NavLink>
            </Box>

            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <NavLink
                to="/report"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#EB3A18" : "#fff",
                 
                })}
              >
                <ReportIcon fontSize="large" />
              </NavLink>
            </Box>

            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <NavLink
                to="/userReport"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#EB3A18" : "#fff",
                })}
              >
                <PersonOffIcon fontSize="large" />
              </NavLink>
            </Box>
            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <NavLink
                to="/feedbackReport"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#EB3A18" : "#fff",
                })}
              >
                <CommentsDisabledIcon fontSize="large" />
              </NavLink>
            </Box>
            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <NavLink
                to="/contact"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#EB3A18" : "#fff",
                 
                })}
              >
                <ContactPhoneIcon fontSize="large" />
              </NavLink>
            </Box>

            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <NavLink
                to="/blogs"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#EB3A18" : "#fff",
                
                })}
              >
                <BookIcon fontSize="large" />
              </NavLink>
            </Box>

            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <NavLink
                to="/verified-docs"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#EB3A18" : "#fff",
                 
                })}
              >
                <VerifiedIcon fontSize="large" />
              </NavLink>
            </Box>

            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            ></Box>

            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <NavLink
                to="/news-letter"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#EB3A18" : "#fff",
                 
                })}
              >
                <PeopleAltIcon fontSize="large" />
              </NavLink>
            </Box>

            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <NavLink
                to="/termsConditions"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#EB3A18" : "#fff",
                
                })}
              >
                <GavelIcon fontSize="large" />
              </NavLink>
            </Box>

            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <NavLink
                to="/privacyPolicy"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#EB3A18" : "#fff",
                
                })}
              >
                <PolicyIcon fontSize="large" />
              </NavLink>
            </Box>

            <Box
              mb={1}
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <NavLink
                to="/cookie-policy"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#EB3A18" : "#fff",
                  
                })}
              >
                <CookieIcon fontSize="large" />
              </NavLink>
            </Box>
          </Box>

            
        </Box>
      </>
    );
  }

  return (
    <>
      <Box
        py={2}
        px={1.5}
        sx={{
       
          height: "100%",
          minWidth: "250px",
          background: "#69C3F9",
          boxShadow: "0 0 8px rgba(0,0,0,0.1)",
          borderRadius: "7px",
        }}
      >
        <Box
          sx={{
            height: "70px",
            color: "white",

            width: "100%",
            maxWidth: "1400px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "95%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                mb={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div></div>
                
                <Box>
                  <Typography
                    onClick={() => setShortNav(false)}
                    sx={{ cursor: "pointer" }}
                  >
                    <MenuOpenIcon fontSize="large" />
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  px: 1,
                }}
              >
                <Box
                  
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                  >
                    <NavLink
                      to="/"
                      style={({ isActive }) => ({
                        textDecoration: "none",
                        color: isActive ? "#EB3A18" : "#fff",
                        width: "100%",
                      })}
                      className="!text-[1.1rem]"
                    >
                      Dashboard
                    </NavLink>
                  </Typography>
                </Box>
                <Box
                  sx={
                    {
                      // border:"1px solid red"
                    }
                  }
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                  >
                    <NavLink
                      to="/user"
                      style={({ isActive }) => ({
                        textDecoration: "none",
                        color: isActive ? "#EB3A18" : "#fff",
                        // background: isActive ? "#7600dc" : "#f0f0f0",
                        width: "100%",
                      })}
                      className="!text-[1.1rem]"
                    >
                      User
                    </NavLink>
                  </Typography>
                </Box>
                {userInfo === "admin" ? (
                  <>
                    <Box
                     
                    >
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                      >
                        <NavLink
                          to="/admin-lists"
                          style={({ isActive }) => ({
                            textDecoration: "none",
                            color: isActive ? "#EB3A18" : "#fff",
                            width: "100%",
                          })}
                          className="!text-[1.1rem]"
                        >
                          Admins
                        </NavLink>
                      </Typography>
                    </Box>
                    <Box
                      sx={
                        {
                          // border:"1px solid red"
                        }
                      }
                    >
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                      >
                        <NavLink
                          to="/activities"
                          style={({ isActive }) => ({
                            textDecoration: "none",
                            color: isActive ? "#EB3A18" : "#fff",
                            width: "100%",
                          })}
                          className="!text-[1.1rem]"
                        >
                          Activity
                        </NavLink>
                      </Typography>
                    </Box>
                  </>
                ) : null}
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <NavLink
                    to="/post"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "#EB3A18" : "#fff",
                    })}
                    className="!text-[1.1rem]"
                  >
                    Post
                  </NavLink>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <NavLink
                    to="/report"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "#EB3A18" : "#fff",
                    })}
                    className="!text-[1.1rem]"
                  >
                    Reported Posts
                  </NavLink>
                </Typography>

                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <NavLink
                    to="/userReport"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "#EB3A18" : "#fff",
                    })}
                    className="!text-[1.1rem]"
                  >
                    Reported User
                  </NavLink>
                </Typography>

                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <NavLink
                    to="/feedbackReport"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "#EB3A18" : "#fff",
                    })}
                    className="!text-[1.1rem]"
                  >
                    Reported Feedback
                  </NavLink>
                </Typography>

                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <NavLink
                    to="/verified-docs"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "#EB3A18" : "#fff",
                    })}
                    className="!text-[1.1rem]"
                  >
                    Verify Document
                  </NavLink>
                </Typography>

                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <NavLink
                    to="/contact"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "#EB3A18" : "#fff",
                    })}
                    className="!text-[1.1rem]"
                  >
                    Enquries
                  </NavLink>
                </Typography>

                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <NavLink
                    to="/blogs"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "#EB3A18" : "#fff",
                    })}
                    className="!text-[1.1rem]"
                  >
                    Blogs
                  </NavLink>
                </Typography>

                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <NavLink
                    to="/news-letter"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "#EB3A18" : "#fff",
                    })}
                    className="!text-[1.1rem]"
                  >
                    Subscribers
                  </NavLink>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <NavLink
                    to="/termsConditions"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "#EB3A18" : "#fff",
                    })}
                    className="!text-[1.1rem]"
                  >
                    Terms & Conditions
                  </NavLink>
                </Typography>

                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <NavLink
                    to="/privacyPolicy"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "#EB3A18" : "#fff",
                      // background: isActive ? "#7600dc" : "#f0f0f0",
                    })}
                    className="!text-[1.1rem]"
                  >
                    Privacy Policy
                  </NavLink>
                </Typography>

                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <NavLink
                    to="/cookie-policy"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "#EB3A18" : "#fff",
                    })}
                    className="!text-[1.1rem]"
                  >
                    Cookie Policy
                  </NavLink>
                </Typography>
              </Box>
            </Box>

           
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Headers;
