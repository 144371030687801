import React, { useEffect, useState } from "react";

import useApi from "../../hooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../components/Loading";

import { useDispatch, useSelector } from "react-redux";

function UploadPhoto() {
  const users = useSelector((state) => state.users?.userInfo);
  console.log(users, "users");

  const { data, loading, error, put } = useApi();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data) {
      setIsLoading(false);
      toast(data?.message || "internal server error");
    }
    if (loading) {
      setIsLoading(true);
    }
    if (error) {
      setIsLoading(false);
      toast(error?.response?.data?.message || "internal server error");
    }
  }, [data, loading, error]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [base64Image, setBase64Image] = useState(null);

  useEffect(() => {
    setSelectedImage(users.profilePic);
  }, [users]);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const image = URL.createObjectURL(file);
      setSelectedImage(image);

      // Convert the image to WebP and then to Base64
      convertToWebP(file);
    }
  };

  const convertToWebP = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        // Convert to WebP and get the Base64 data URL
        const webpDataUrl = canvas.toDataURL("image/webp");
        setBase64Image(webpDataUrl.split(",")[1]); // Remove the data URL prefix if only base64 is needed
      };
    };
    reader.readAsDataURL(file); // Read the image file as a data URL
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setBase64Image(null);
  };

  const uploadImage = async () => {
    if (!base64Image) {
      toast("please upload image");
      return;
    }
    try {
      await put("/api/user/updateProfile", { file: base64Image });
    } catch (err) {
    } finally {
    }
  };

  return (
    <>
      <ToastContainer />

      {isLoading && <Loading loading={isLoading} />}

      <div className="flex flex-col items-center p-6 rounded-md shadow-md mx-auto w-[70%]">
        <h2 className="text-2xl font-semibold mb-4">Upload Photo</h2>
        <div className="w-[10rem] h-[10rem] rounded-[50%] bg-gray-800 flex items-center justify-center mb-4 rounded overflow-hidden">
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Uploaded"
              className="bg-contain w-full h-full rounded-[50%]"
            />
          ) : (
            <span className="text-gray-500">No image selected</span>
          )}
        </div>
        <div className="w-full flex justify-center">

        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="block w-full text-sm text-gray-400 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-600 file:text-white hover:file:bg-blue-700"
        />
        </div>
        <div className="flex gap-4 mt-4">
          <button
            onClick={uploadImage}
            className="px-4 py-2 bg-blue-600 rounded text-white hover:bg-blue-700"
          >
            Upload Photo
          </button>
          {/* <button
            onClick={handleRemoveImage}
            className="px-4 py-2 bg-red-600 rounded text-white hover:bg-red-700"
          >
            Remove Photo
          </button> */}
        </div>
      </div>
    </>
  );
}

export default UploadPhoto;
