// import cogoToast from 'cogo-toast';
import {toast,Bounce} from 'react-toastify';
const useMakeToast = () => {
    const makeToast = (content, type = '', hideAfter = 1, heading = '') => {
        const options = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        };
        if (type === 'success') toast.success(content, options);
        else if (type === 'error') toast.error(content, options);
        else if (type === 'warn') {
            toast.warn(content, options);
        } else if (type === 'info') {
            toast.info(content, options);
        } else {
            toast(content, options);
        }
    };

    return makeToast;
};

export default useMakeToast;
