import {Typography} from "@mui/material";
import React from "react";

export const tableCountShow = (state) => {
   return  <Typography
        sx={{
            textTransform: "capitalize",
            fontSize: "15px",
            fontWeight: "500",
            color: state > 0 ? '':"red"
        }}
    >
        {state}
    </Typography>
}
export const tableText = (state, msg = 'Not Avalaible') => {
   return  <Typography
        sx={{
            textTransform: "capitalize",
            fontSize: "15px",
            fontWeight: "500",
            color: state ? '' : "red"
        }}
    >
        {state || msg}
    </Typography>
}