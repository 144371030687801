import axios from "axios";
export const URL = process.env.REACT_APP_BACKEND_URL;

axios.interceptors.request.use(
  (config) => {
    config.timeout = 40000;
    const accessToken = localStorage.getItem("accessToken");
    if (!config.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    console.log(config);
    return config;
  },
  (error) => Promise.reject(error)
);

export const allUsers = async (
  page = null,
  pageSize = null,
  searchTerm,
  filters
) => {
  try {
    const response = await axios.get(
      `${URL}/api/user/allUser?page=${page}&limit=${pageSize}&search=${searchTerm}&filters=${JSON.stringify(
        filters
      )}`
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};

export const allUsersCount = async () => {
  try {
    const response = await axios.get(`${URL}/api/user/all-users-stats`);
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};

export const allPost = async () => {
  try {
    const response = await axios.get(`${URL}/api/post/allPost`);
    return response;
  } catch (err) {
    throw err?.response;
  }
};

export const userInfo = async () => {
  try {
    const response = await axios.get(`${URL}/api/auth/getUser`);
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};

export const deleteUser = async (data) => {
  try {
    const response = await axios.delete(`${URL}/api/user/deleteUser`, {
      data: data,
    });
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};
export const blockUnblockUser = async (userId) => {
  try {
    const response = await axios.post(`${URL}/api/user/blockedUserByAdmin`, {
      userId: userId,
    });
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};

// /delete/:postId

export const deletePost = async (data) => {
  try {
    const response = await axios.delete(`${URL}/api/post/delete/${data}`, {
      data: data,
    });
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};

export const signUp = async (data) => {
  try {
    const response = await axios.post(`${URL}/api/auth/register`, data);
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};

export const createPost = async (data) => {
  try {
    const response = await axios.post(`${URL}/api/post/create`, data);
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};

export const addTermsConditions = async (data) => {
  try {
    const response = await axios.post(`${URL}/api/termsConditions`, data);
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};

export const createPrivacyPolicy = async (data) => {
  try {
    const response = await axios.post(`${URL}/api/privacyPolicy`, data);
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};

export const createCookiePolicy = async (data) => {
  try {
    const response = await axios.post(`${URL}/api/cookiePolicy`, data);
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};

export const getAllReports = async (
  page,
  pageSize,
  searchTerm,
  filters,
  type
) => {
  try {
    const response = await axios.get(
      `${URL}/api/report/submitReport?page=${page}&limit=${pageSize}&search=${searchTerm}&type=${type}&filters=${JSON.stringify(
        filters
      )}`
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};

export const deleteReport = async (data) => {
  console.log("delete report api", data);
  try {
    const response = await axios.delete(`${URL}/api/report/submitReport`, {
      data,
    });
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};


export const deleteFeedback = async (data) => {
  try {
    const response = await axios.delete(`${URL}/api/report/feedback-report`, {
      data,
    });
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};



export const getAllUserReport = async (page, pageSize, searchTerm, filters) => {
  try {
    const response = await axios.get(
      `${URL}/api/report/userReport?page=${page}&limit=${pageSize}&search=${searchTerm}&filters=${JSON.stringify(
        filters
      )}`
    );
    if (response) {
      return response;
    }
  } catch (err) {
    throw err?.response;
  }
};

export const deleteUserReport = async (data) => {
  try {
    const response = await axios.delete(`${URL}/api/report/userReport`, {
      data,
    });
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};

export const getAllIdentificationReq = async () => {
  try {
    const response = await axios.get(`${URL}/api/user/identify-docs`);
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};

export const verifyDocument = async (data) => {
  try {
    const response = await axios.post(
      `${URL}/api/user/approve-identify-docs`,
      data
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};
export const PostData = async (data, endpoint) => {
  try {
    const response = await axios.post(`${URL}${endpoint}`, data);
    if (response) {
      return response.data;
    }
  } catch (err) {
    throw err?.response?.data;
  }
};

export const GetRequest = async (endpoint) => {
  try {
    const response = await axios.get(`${URL}${endpoint}`);
    if (response) {
      return response.data;
    }
  } catch (err) {
    return err?.response?.data;
  }
};

export const getActivities = async (page, pageSize, searchTerm, typeFilter) => {
  try {
    const response = await axios.get(
      `${URL}/api/user/getActivities?page=${page}&limit=${pageSize}&search=${searchTerm}&typeFilter=${typeFilter}`
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err?.response;
  }
};
