import "./App.css";
import Headers from "./components/headers";
import { Route, Routes } from "react-router-dom";
import Login from "./layouts/login/login";
import Register from "./layouts/register/register";
import LandingPage from "./layouts/landingPage/landingPage";
import CheckHeader from "./components/checkHeader";
import UsePersistLogin from "./hooks/usePersistLogin";
import UserPage from "./layouts/userPage/userPage";
import PostPage from "./layouts/Post/postPage";
import ReportPage from "./layouts/reports/reportPage";
import CreateBlog from "./layouts/termsConditions/termsConditions";
import PrivacyPolicy from "./layouts/privacyPolicy/privacyPolicy";
import UserReportPage from "./layouts/userReport/userReports";
import VerifiedDocs from "./layouts/verifyDocs/verifiedDocs";
import Admins from "./layouts/addadmins/Admins";
import ActivitiesPage from "./layouts/activityPage/activitiesPage";
import { GlobalStyles } from "@mui/material";
import Blogs from "./layouts/blogs/blogs";
import AllBlogs from "./layouts/blogs/allBlogs";
import ContactUs from "./layouts/contactUs/contactUs";

import { ConfirmProvider } from "material-ui-confirm";
import NewsLetter from "./layouts/newsLetter/newsLetter";

import "react-quill/dist/quill.snow.css";
import NewsLetterList from "./layouts/newsLetter/newsLetterList";

import BlogIndex from "./layouts/blogs/Index";
import BlogCategory from "./layouts/blogs/BlogCategory";

import NewsLetterIndex from "./layouts/newsLetters/index";
import CookiePolicy from "./layouts/cookiePolicy/cookiePolicy";

import FeedbackReport from "./layouts/feedbackReport/feedbackReport";
import UploadPhoto from "./layouts/uploadPhoto/UploadPhoto";

function App() {
  GlobalStyles({
    "@global": {
      body: {
        fontsize: "14px",
      },
    },
  });
  return (
    <div>
      {/* <Headers /> */}
      <ConfirmProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          <Route element={<UsePersistLogin />}>
            <Route element={<CheckHeader />}>
              <Route path="/" element={<LandingPage />} />

              <Route path="/upload-photo" element={<UploadPhoto />} />

              <Route path="/user" element={<UserPage />} />
              <Route path="/activities" element={<ActivitiesPage />} />
              <Route path="/post" element={<PostPage />} />
              <Route path="/report" element={<ReportPage />} />
              <Route path="/admin-lists" element={<Admins />} />
              <Route path="/termsConditions" element={<CreateBlog />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />

              <Route path="/userReport" element={<UserReportPage />} />
              <Route path="/feedbackReport" element={<FeedbackReport />} />

              <Route path="/verified-docs" element={<VerifiedDocs />} />

              <Route path="/blogs" element={<BlogIndex />}>
                <Route index element={<AllBlogs />} />
                <Route path="blogs" element={<AllBlogs />} />
                <Route path="create-blog" element={<Blogs />} />
                <Route path="blog-category" element={<BlogCategory />} />
              </Route>

              <Route path="/contact" element={<ContactUs />} />

              <Route path="/news-letter" element={<NewsLetterIndex />}>
                <Route index element={<NewsLetterList />} />
                <Route path="list" element={<NewsLetterList />} />
                <Route path="create-news-letter" element={<NewsLetter />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </ConfirmProvider>
    </div>
  );
}

export default App;
