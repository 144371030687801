import React, { useEffect, useRef, useState } from "react";
import styles from "./contactUs.module.css";

import useApi from "../../hooks/useApi";
import Loading from "../../components/Loading";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const scrollDivRef = useRef(null);

  const [page, setPage] = useState(1);

  const [contactData, setContactData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [totalData, setTotalData] = useState(10); 

  const { data, loading, error, get } = useApi();

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
      return;
    }
    if (error) {
      setErrMsg(error?.response?.data?.message || "internal server error");
      setIsLoading(false);

      return;
    }
    if (data) {
      setIsLoading(false);
      console.log(data.data)
      setTotalData(Math.ceil(data.totalCount / 10));

      setContactData(data.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    getContactData(1);
  }, []);

  const getContactData = (pageNo) => {
    get("/api/home/contact", { limit: 10, page: pageNo });
  };

  const handlePageChange = (event, value) => {
    getContactData(value);
    setPage(value);
  };

  if (isLoading) {
    return (
      <>
        <Loading loading={isLoading} />
      </>
    );
  }

  return (
    <>
      {errMsg && <p className="error-msg-p ">{errMsg}</p>}

      <h1 className={styles["website-query-heading"]}>Website Queries</h1>
      <table className={`table-auto w-full ${styles["table"]}`}>
        <thead
          className={`${styles.tableHeader} pop-font text-xs 2xl:text-base text-left`}
        >
          <tr className="font-semibold">
            <th className="md:font-medium py-4 px-4 2xl:px-5">S.No</th>
            <th className="md:font-medium py-4 px-4 2xl:px-5">Name</th>
            <th className="md:font-medium py-4 px-4 2xl:px-5">Email</th>
            <th className="md:font-medium py-4 px-4 2xl:px-5">Phone No</th>
            <th className="md:font-medium py-4 px-4 2xl:px-5">Query</th>
          </tr>
        </thead>
        <tbody className="px-2 md:text-xs 2xl:text-base">
          {contactData.map((ele, index) => {
            return (
              <tr key={index} className="border-b">
                <td className={` py-7 pl-4 pr-2 2xl:px-5 font-bold text-black`}>
                  {index + 1}
                </td>
                <td className={`${styles.tableData} py-7 px-4 2xl:px-5`}>
                  {ele.name}
                </td>
                <td
                  className={`${styles.tableData} py-7 px-4 2xl:px-5 flex gap-1 align-middle`}
                >
                  {ele.email}
                  <Link to={`mailto:${ele.email}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#5f6368"
                    >
                      <path d="M760-200v-160q0-50-35-85t-85-35H273l144 144-57 56-240-240 240-240 57 56-144 144h367q83 0 141.5 58.5T840-360v160h-80Z" />
                    </svg>
                  </Link>
                </td>
                <td className={`${styles.tableData} py-7 px-4 2xl:px-5`}>
                  {ele.contactNo}
                </td>
                <td className={`${styles.tableData} py-7 px-4 2xl:px-5`}>
                  {ele.message}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Stack spacing={2} className={styles["pagination-wrapper"]}>
        <Pagination
          count={totalData}
          color="primary"
          onChange={handlePageChange}
          page={page}
        />
      </Stack>
    </>
  );
};

export default ContactUs;
