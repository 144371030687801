import React, { useState, useEffect } from "react";
import { getAllReports } from "../../api/api";
import {
  Box,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Modal,
} from "@mui/material";
import useMakeToast from "../../hooks/makeToast";
import { deleteFeedback } from "../../api/api";
import Loading from "../../components/Loading";
import { ToastContainer } from "react-toastify";
import FilterDropDown from "../../components/filterDropDown";
import AlertDialog from "../../components/AlertDialog";
import { tableCountShow, tableText } from "../../components/miniComponents";
import TableSkeleton from "../../components/TableSkeleton";

function FeedbackReport() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCounts, setTotalCounts] = useState(1);
  const [allReports, setAllReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const makeToast = useMakeToast();
  const [searchTerm, setSearchTerm] = useState("");

  //images
  const [showModal, setShowModal] = useState(false);

  const handleSearch = async (e) => {
    setSearchTerm(e.target.value);
    // await fetchAllReports();
  };
  const fetchAllReports = async () => {
    const response = await getAllReports(
      page,
      pageSize,
      searchTerm,
      {
        reporterType: reporterType,
      },
      "comment"
    );
    if (response) {
      // const postReport = response?.data?.data.filter(
      //   (el) => el?.label === "comment"
      // );
      setAllReports(response?.data?.data);
      setTotalCounts(response?.data?.totalReports);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const [openAlert, setOpenAlert] = useState(false);

  const [feedbackReportId, setFeedbackReportId] = useState(null);
  const [feedbackId, setFeedbackId] = useState(null);
  const [reporterType, setReporterType] = useState(null);
  const optionsReporters = [
    { value: null, label: "Reporter Type" },
    { value: "seller", label: "Seller" },
    { value: "consultant", label: "Consultant" },
    { value: "supplier", label: "Supplier" },
  ];
  const tableHead = [
    "Heading",
    "Description",

    "Reporter Name",
    "Reporter Email",
    "Scammer Name",
    "Scammer Email",

    <FilterDropDown
      options={optionsReporters}
      value={reporterType}
      setValue={setReporterType}
    />,
    "Delete Feedback",
  ];
  const [tableSkeleton, setTableSkeleton] = useState(true);

  const handleDelete = (row) => {
    setFeedbackReportId(row._id);
    setFeedbackId(row.scammerId._id);
    setOpenAlert(true);
  };
  useEffect(() => {
    fetchAllReports().then(() => setTableSkeleton(false));
  }, [page, pageSize, reporterType]);

  useEffect(() => {
    if (searchTerm !== "") {
      setTableSkeleton(true);
      fetchAllReports().then(() => setTableSkeleton(false));
    }
  }, [searchTerm]);

  const deleteFeedbackHandler = async () => {
    const response = await deleteFeedback({ feedbackReportId, feedbackId });
    if (response) {
      const newPostList = allReports.filter(
        (el) => el?._id !== feedbackReportId
      );
      setAllReports(newPostList);
      makeToast("Post deleted successfully", "success", 3);
    } else {
      makeToast("Post deleted Not Deleted", "warn", 3);
    }
  };
  if (loading) {
    return (
      <>
        <Loading loading={loading} />
      </>
    );
  }
  return (
    <>
      <ToastContainer />
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {/* sx={{ maxHeight: 440 }} */}
        <TableContainer>
          <Box
            p={1}
            sx={{
              display: "flex",
            }}
          >
            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onInput={handleSearch}
              sx={{ width: "100%" }}
            />
          </Box>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableHead.map((_, idx) => (
                  <TableCell key={idx}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 600,
                      }}
                    >
                      {_}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableSkeleton ? (
                <TableSkeleton
                  rowsLength={allReports.length}
                  circular={2}
                  count={11}
                />
              ) : allReports.length > 0 ? (
                allReports.map((row, index) => {
                  const reporterName = `${row?.reporterId?.firstName} ${row?.reporterId?.lastName}`;
                  return (
                    <TableRow hover tabIndex={-1} key={row?._id}>
                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            {row?.heading}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            {row?.description}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {tableText(reporterName)}
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {tableText(row?.reporterId?.email)}
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {tableText(
                            row?.scammerId?.author.firstName +
                              row?.scammerId?.author.lastName
                          )}
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {tableText(
                            row?.scammerId?.author.email
                          )}
                        </Box>
                      </TableCell>


                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {tableText(row?.reporterId?.accountType)}
                        </Box>
                      </TableCell>
                      {console.log(row, "sfsf")}

                      <TableCell align="left">
                        <button
                          style={{
                            padding: "5px",
                            minWidth: "80px",
                            border: "none",
                            outline: "none",
                            borderRadius: "4px",
                            backgroundColor: "#F24C3D",
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDelete(row)}
                        >
                          Delete
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    <Typography>No Data Found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={totalCounts}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(e) => {
            setPageSize(parseInt(e.target.value, 10));
            setPage(0);
          }}
        />
      </Paper>
      {allReports.length === 0 ? (
        <Box
          sx={{
            minHeight: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "600",
              color: "#888888",
            }}
          >
            There in no Record Available At this Moment
          </Typography>
        </Box>
      ) : (
        ""
      )}
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0",
          margin: "0",
          height: "100vh",
        }}
      >
        <div
          className="slider-container"
          style={{
            maxWidth: "900px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <Slider {...settings} style={{ width: "100%", height: "100%" }}>
            {allReports[selectedPhotoIndex]?.scammerId?.file.map(
              (file, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {file.type === "image" ? (
                    <img
                      src={file.fileKey}
                      alt={`Photo ${index}`}
                      style={{
                        maxWidth: "900px",
                        minHeight: "900px",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <video
                      src={file?.fileKey}
                      autoPlay
                      loop
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </div>
              )
            )}
          </Slider> */}
        </div>
      </Modal>
      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        title={"Delete Reported Post"}
        functionToPerform={deleteFeedbackHandler}
      />
    </>
  );
}

export default FeedbackReport;
