import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createCookiePolicy, GetRequest } from "../../api/api";
import useMakeToast from "../../hooks/makeToast";
import { ToastContainer } from "react-toastify";
import Loading from "../../components/Loading";

var quill;
const modules = {
  toolbar: {
    container: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
    handlers: {
      image: imageHandler,
    },
  },
};

function imageHandler() {
  var range = this.quill.getSelection();
  var value = prompt("please copy paste the image url here.");
  if (value) {
    this.quill.insertEmbed(range.index, "image", value);
  }
}

const CookiePolicy = () => {
  const theme = useTheme();
  const [value, setValue] = useState("");
  const [allow, setAllow] = useState(true);
  const makeToast = useMakeToast();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCookieData = async () => {
      setLoading(true);
      const response = await GetRequest("/api/cookiePolicy");
      if (response?.status === "success") {
        setValue(response?.data?.cookiePolicy);
      }
      setLoading(false);
    };
    getCookieData();
  }, []);

  const postBlogs = async () => {
    if (!allow) return false;
    if (!value) {
      makeToast("Please write some data", "error", 3);
      return false;
    }
    setAllow(false);
    setLoading(true);
    const response = await createCookiePolicy({ cookiePolicy: value });
    if (response?.data?.status === "success") {
      makeToast("Cookie Policy added successfully", "success", 4);
      setValue(response.data.data.cookiePolicy);
      setAllow(true);
    }
    setLoading(false);
  };
  if (loading) {
    return (
      <>
        <Loading loading={loading} />
      </>
    );
  }
  return (
    <>
      <ToastContainer />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              bgcolor: `${theme.palette.background.light}`,
              alignItems: "center",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              boxShadow: "0 0 2px rgba(0,0,0,0.5)",
            }}
            p={1}
            mx={1.1}
            mt={1}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                color: `${theme.palette.text.primary}`,
              }}
            >
              Write Cookie Policy Here
            </Typography>
          </Box>
          <Box px={1}>
            <ReactQuill
              ref={(el) => {
                quill = el;
              }}
              modules={modules}
              theme="snow"
              onChange={setValue}
              value={value}
              placeholder="Content goes here..."
              height="1000px"
              width="100%"
              style={{
                color: `${theme.palette.background.primary}`,
              }}
            />
            <Box
              mx={0.1}
              px={1}
              sx={{
                bgcolor: `${theme.palette.background.light}`,
                height: "50px",
                borderBottomLeftRadius: "7px",
                borderBottomRightRadius: "7px",
                boxShadow: "0 0 2px rgba(0,0,0,0.5)",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: "10px",
                }}
              >
                <button
                  style={{
                    backgroundColor: "#F49D1A",
                    padding: "7px 14px",
                    outline: "none",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    color: "white",
                    letterSpacing: "1px",
                    fontSize: "16px",
                    fontWeight: "500",
                    width: "70px",
                  }}
                  onClick={() => setValue(null)}
                >
                  Clear
                </button>
                <button
                  style={{
                    backgroundColor: "#285430",
                    padding: "7px 14px",
                    outline: "none",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    color: "white",
                    letterSpacing: "1px",
                    fontSize: "16px",
                    fontWeight: "500",
                    width: "70px",
                  }}
                  onClick={postBlogs}
                >
                  Post
                </button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CookiePolicy;
