import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { allUsers, allPost, allUsersCount } from "../../api/api";
import ChartsWrapper from "./ChartsWrapper";
import { useNavigate } from "react-router-dom";
const LandingPage = () => {
  // const [allUser, setAllUser] = useState([]);
  const [countryStats, setCountryStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [usersSignedUp, setUsersSignedUp] = useState({
    countLast24Hours: 0,
    countLastWeek: 0,
    countLastMonth: 0,
  });
  const [usersCount, setUsersCount] = useState({
    seller: 0,
    supplier: 0,
    consultant: 0,
    allUsers: 0,
  });
  const [howManyPost, setHowManyPost] = useState(0);

  const getAllUsersCount = async () => {
    const response = await allUsersCount();
    console.log("response?.data?.response", response);
    if (response) {
      let data = response?.data?.stats?.counts;
      data.allUsers = response?.data?.stats?.allUsers;
      setUsersCount(data);
      setCountryStats(response?.data?.stats?.countries);
      const signee = response?.data?.stats?.signupByTime;
      setUsersSignedUp(signee);
      setLoading(true);
      console.log("usersSignedUp", usersSignedUp, "response?.data?.response");
    }
  };
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
  };
  const fetchAllPost = async () => {
    try {
      const response = await allPost();
      console.log("response", response);
      if (response) {
        let post = response?.data?.posts;
        setHowManyPost(post);
      } else {
        setHowManyPost(0);
      }
    } catch (err) {
      console.log(err);
    }
  };
  // useEffect(() => { fetchAllPost().then(() => getAllUsersCount() ) } , [] );
  useEffect(() => {
    Promise.all([fetchAllPost(), getAllUsersCount()])
      .then(() => {
        console.log("Done");
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
      });
  }, []);

  const boxData = [
    {
      label: "Total User",
      value: usersCount.allUsers || 0,
      path: "/user",
    },
    {
      label: "Seller User",
      value: usersCount.seller || 0,
      path: `/user?type=seller`,
    },
    {
      label: "Consultant User",
      value: usersCount.consultant || 0,
      path: `/user?type=consultant`,
    },
    {
      label: "Supplier User",
      value: usersCount.supplier || 0,
      path: `/user?type=supplier`,
    },
    {
      label: "Total Post",
      value: howManyPost,
      path: "/post",
    },
  ];
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          p={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            flexWrap: "wrap",
            maxWidth: "1400px",
            width: "100%",
          }}
        >
          {boxData.map((data, index) => (
            <Box
              key={index}
              sx={{
                width: "300px",
                height: "80px",
                boxShadow: "0 0 5px rgba(0,0,0,0.4)",
                borderRadius: "10px",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => handleNavigate(data.path)}
            >
              <Box
                sx={{
                  minWidth: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  p={1}
                  sx={{
                    fontSize: "26px",
                    fontWeight: "600",
                    color: "#525FE1",
                  }}
                >
                  {data.value}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  flexDirection: "column",
                }}
                px={1}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#002B5B",
                    }}
                  >
                    {data.label}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      {/* <ChartsWrapper
        usersCount={usersCount}
        countryStats={countryStats}
        loading={loading}
        usersSignedUp={usersSignedUp}
      /> */}
    </>
  );
};
export default LandingPage;
