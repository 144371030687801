import React from 'react'

import { Outlet } from 'react-router-dom'
import BlogsHeader from './BlogsHeader'

const Index = () => {
  return (
    <>
    <BlogsHeader/>

    <Outlet/>
    </>
  )
}

export default Index