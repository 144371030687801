import React, { useEffect, useState } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import styles from "./allBlog.module.css";

import { useNavigate } from "react-router-dom";

import useApi from "../../hooks/useApi";
import { ErrorOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";

import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";

import { useConfirm } from "material-ui-confirm";

import EditIcon from "@mui/icons-material/Edit";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import BlogContentComponent from "./blogPreview";
import Loading from "../../components/Loading";

export default function AllBlogs() {
  const confirm = useConfirm();

  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const [blogVisibilityFilter, setBlogVisibilityFilter] = useState("");

  const [isError, setIsError] = useState(false);
  const { data, loading, error, get } = useApi();
  const {
    data: visibilityData,
    loading: visibilityLoading,
    error: visibilityError,
    patch,
  } = useApi();

  const {
    data: dataCategory,
    loading: dataLoading,
    error: dataError,
    get: getCategory,
  } = useApi();

  const {
    data: deleteBlogData,
    loading: deleteBlogLoading,
    error: deleteBlogError,
    del,
  } = useApi();

  const [page, setPage] = useState(1);
  const [blogs, setBlogs] = useState([]);

  const [totalBlogs, setTotalBlogs] = useState(0);

  const [openPreview, setOpenPreview] = useState(false);

  const [oneBlogData, setOneBlogData] = useState([]);

  const [allCategoryData, setAllCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  //get category

  useEffect(() => {
    if (dataLoading) {
    }
    if (dataError) {
      // setIsError(dataError.response.data.message);
      toast(dataError.response.data.message);
    }
    if (dataCategory) {
      setAllCategoryData(dataCategory.data);
    }
  }, [dataCategory, dataLoading, dataError]);

  //get blog

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
      return;
    }
    if (error) {
      setIsError(error?.response?.data?.message || "internal server error");
      toast(error?.response?.data?.message);
      setIsLoading(false);
      return;
    }
    if (data) {
      setIsLoading(false);
      setBlogs(data.data);
      setTotalBlogs(Math.ceil(data.totalBlogs / 6));
      return;
    }
  }, [data, loading, error]);

  //delete blog

  useEffect(() => {
    if (deleteBlogLoading) {
      setDeleteLoader(true);
      return;
    }
    if (deleteBlogError) {
      setIsError(
        deleteBlogError?.response?.data?.message || "internal server error"
      );
      toast(
        deleteBlogError?.response?.data?.message || "internal server error"
      );
      setDeleteLoader(false);
      return;
    }
    if (deleteBlogData) {
      setDeleteLoader(false);
      console.log(deleteBlogData);
      toast(deleteBlogData?.message || "Category Deleated");

      fetchBlogs(page);
      return;
    }
  }, [deleteBlogData, deleteBlogLoading, deleteBlogError]);

  //change visibility

  useEffect(() => {
    if (visibilityLoading) {
      setIsLoading(true);
      return;
    }
    if (visibilityError) {
      // setIsError(
      //   visibilityError?.response?.data?.message || "internal server error"
      // );
      toast(
        visibilityError?.response?.data?.message || "internal server error"
      );
      setIsLoading(false);
      return;
    }
    if (visibilityData) {
      setIsLoading(false);
      // setBlogs(data.data);
      // handlePageChange(page)
      toast(visibilityData.message);
      setBlogs((prv) => {
        return prv.map((ele) => {
          if (ele._id == visibilityData.blogId) {
            return { ...ele, isActive: !ele.isActive };
          } else {
            return ele;
          }
        });
      });

      return;
    }
  }, [visibilityData, visibilityLoading, visibilityError]);

  const navigate = useNavigate();

  const goToBlog = (id, pageRoute) => {
    navigate(`/blogs/create-blog?id=${id}&route=${pageRoute}`);
  };

  useEffect(() => {
    getCategory("/api/blog/category?isAdmin=true");
    fetchBlogs(1);
  }, []);

  const fetchBlogs = async (pageNoo, status, category) => {
    //active,inactive,all
    get(`/api/blog/all-blogs`, {
      limit: "10",
      page: pageNoo,
      blogStatus: status,
      blogCategory: category,
    });
  };

  const handlePageChange = (event, value) => {
    fetchBlogs(value, blogVisibilityFilter);
    setPage(value);
  };

  const deleteBlog = (e, blogId) => {
    e.stopPropagation();
    confirm({ description: "Are you sure you want to delete this Blog!" })
      .then(() => {
        del(`/api/blog/delete-blog/${blogId}`);
      })
      .catch((e) => {
        setIsError(e);
      });
  };

  const changeVisibility = (blogId, active) => {
    confirm({ description: `make this blog ${active ? "inactive" : "active"}` })
      .then(() => {
        patch(`/api/blog/manage-blog/${blogId}`, {}, { activeStatus: !active });
      })
      .catch((e) => {
        setIsError(e);
      });
  };

  const formatTimestamp = (timestamp) => {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp);

    // Define arrays to get month and day names
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Get the components of the date
    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    // Determine AM or PM suffix
    const ampm = hours >= 12 ? "pm" : "am";

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format minutes to be two digits
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    // Construct the formatted date string
    const formattedDate = `${month} ${day} ${year}, ${hours}:${formattedMinutes} ${ampm}`;

    return formattedDate;
  };

  const selectHandler = (val) => {
    fetchBlogs(1, val, selectedCategory);
    setBlogVisibilityFilter(val);
    setPage(1);
  };

  const preView = (blogData) => {
    setOpenPreview(true);
    setOneBlogData(blogData);
  };

  const categoryHandler = (id) => {
    fetchBlogs(1, blogVisibilityFilter, id);
    setPage(1);
    setSelectedCategory(id);
  };

  return (
    <>
      <ToastContainer />

      {isError && <p className="error-msg-p ">{isError}</p>}

      <>
        {/* <h1 className={styles["website-query-heading"]}>ALL Blogs</h1> */}

        <div className="flex gap-[2%] w-[100%]">
          <div className={styles["select-wrapper"]}>
            <select
              className={styles["select-box"]}
              onChange={(e) => selectHandler(e.target.value)}
            >
              <option selected disabled>
                Filter by status
              </option>

              <option value="">All</option>

              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div className={styles["select-wrapper"]}>
            <select
              className={styles["select-box"]}
              onChange={(e) => categoryHandler(e.target.value)}
            >
              <option selected disabled>
                Filter by category
              </option>
              <option value="">All</option>
              {allCategoryData.map((ele) => {
                return <option value={ele._id}>{ele.name}</option>;
              })}
            </select>
          </div>
        </div>

        {blogs.length == 0 && (
          <h2 className="text-center my-[1rem]">No Blogs to display</h2>
        )}

        {blogs.length != 0 && (
          <table className="table-auto w-full h-max max-h-[60vh] overflow-scroll my-[2%]">
            <thead className="pop-font text-xs 2xl:text-base text-center">
              <tr className="font-[100] text-[0.95rem] text-[#696565fd] bg-slate-200">
                <th className="py-4 px-4 2xl:px-5">S.No</th>
                <th className="py-4 px-4 2xl:px-5 whitespace-nowrap">Title </th>
                <th className="py-4 px-4 2xl:px-5">Blog Category</th>
                <th className="py-4 px-4 2xl:px-5">Author</th>
                <th className="py-4 px-4 2xl:px-5">Page Route</th>
                <th className="py-4 px-4 2xl:px-5">Date</th>
                <th className="py-4 px-4 2xl:px-5">Status</th>

                <th className="py-4 px-4 2xl:px-5">Actions</th>
              </tr>
            </thead>
            <tbody className="px-2 md:text-xs 2xl:text-base">
              {blogs.map((ele, i) => (
                <tr
                  key={i}
                  className="border-b font-[100] text-[0.9rem] text-center text-[#545252]"
                >
                  <td className="py-7 pl-4 pr-2 2xl:px-5">
                    {" "}
                    {(page - 1) * 10 + (i + 1)}
                  </td>
                  <td className="py-1 px-1 w-[1rem]"> {ele.title}</td>
                  <td className="py-7 px-4 2xl:px-5">
                    {" "}
                    {ele?.blogCategoryId?.name}
                  </td>

                  <td className="py-7 px-4 2xl:px-5">
                    {" "}
                    {ele?.authorId?.firstName}
                  </td>

                  <td className="py-7 px-4 2xl:px-5"> {ele?.pageRoute}</td>

                  <td className="py-7 px-4 2xl:px-5">
                    {" "}
                    {formatTimestamp(ele.updatedAt)}
                  </td>

                  <td className="py-7 px-4 2xl:px-5">
                    {" "}
                    {ele.isActive ? (
                      // <RemoveRedEyeIcon className={styles["eye-icon"]} />

                      <button
                        className={`${styles["delete-btn"]} ${styles["preview-btn"]} !bg-[green] text-[#fff]`}
                      >
                        Active
                      </button>
                    ) : (
                      // <VisibilityOffIcon />

                      <button
                        className={`${styles["delete-btn"]} ${styles["preview-btn"]} !bg-[orange] text-[#fff]`}
                      >
                        InActive
                      </button>
                    )}
                  </td>

                  <td>
                    <button
                      className={styles["delete-btn"]}
                      onClick={(e) => changeVisibility(ele._id, ele.isActive)}
                    >
                      {ele.isActive ? (
                        <RemoveRedEyeIcon className={styles["eye-icon"]} />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </button>
                    <button
                      className={styles["delete-btn"]}
                      onClick={() => goToBlog(ele._id, ele.pageRoute)}
                    >
                      <EditIcon className={styles["edit-icon"]} />
                    </button>
                    <button
                      className={styles["delete-btn"]}
                      onClick={(e) => deleteBlog(e, ele._id)}
                    >
                      <DeleteIcon className={styles["delete-icon"]} />
                    </button>
                    <button
                      className={`${styles["delete-btn"]} ${styles["preview-btn"]} ml-[1rem]`}
                      onClick={(e) => preView(ele)}
                    >
                      Preview
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <Stack spacing={2} className={styles["pagination-wrapper"]}>
          <Pagination
            count={totalBlogs}
            color="primary"
            onChange={handlePageChange}
            page={page}
          />
        </Stack>
      </>

      {openPreview && (
        <div className={styles["prview-wrapper"]}>
          <BlogContentComponent
            blogData={oneBlogData}
            setOpenPreview={setOpenPreview}
          />
        </div>
      )}

      {isLoading && <Loading loading={isLoading} />}
    </>
  );
}
