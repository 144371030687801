import React, { useState, useEffect } from "react";
import { getAllUserReport } from "../../api/api";
import {
  Box,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Modal,
} from "@mui/material";
import post from "../../assets/svg/post.svg";
import useMakeToast from "../../hooks/makeToast";
import {
  deletePost,
  deleteReport,
  deleteUser,
  deleteUserReport,
} from "../../api/api";
import Loading from "../../components/Loading";
import person from "../../assets/svg/person.svg";
import { ToastContainer } from "react-toastify";
import Slider from "react-slick";
import AlertDialog from "../../components/AlertDialog";
import FilterDropDown from "../../components/filterDropDown";
import { tableText } from "../../components/miniComponents";
import TableSkeleton from "../../components/TableSkeleton";

function UserReportPage() {
  const [allReports, setAllReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const makeToast = useMakeToast();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCounts, setTotalCounts] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = async (e) => {
    setSearchTerm(e.target.value);
  };

  //images
  const [showModal, setShowModal] = useState(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [userId, setUserId] = useState(null);
  const [listId, setListId] = useState(null);
  const handleDelete = (id, row_id) => {
    setUserId(id);
    setListId(row_id);
    setOpenAlert(true);
  };
  const handlePhotoClick = (index) => {
    setSelectedPhotoIndex(index);
    console.log("index", index, "selectedPhotoIndex File");
    setShowModal(true);
  };
  const fetchAllReports = async () => {
    try {
      const response = await getAllUserReport(page, pageSize, searchTerm, {
        reportedType: reportedType,
        reporterType: reporterType,
      });
      if (response) {
        console.log(response, "response");
        setAllReports(response?.data?.data);
        setTotalCounts(response?.data?.totalUserReports);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [reporterType, setReporterType] = useState(null);
  const optionsReporters = [
    { value: null, label: "Reporter Role" },
    { value: "seller", label: "Seller" },
    { value: "consultant", label: "Consultant" },
    { value: "supplier", label: "Supplier" },
  ];
  const [reportedType, setReportedType] = useState(null);
  const optionsReported = [
    { value: null, label: "Reported User Type" },
    { value: "seller", label: "Seller" },
    { value: "consultant", label: "Consultant" },
    { value: "supplier", label: "Supplier" },
  ];
  const [tableSkeleton, setTableSkeleton] = useState(false);

  useEffect(() => {
    setTableSkeleton(true);
    fetchAllReports().then(() => setTableSkeleton(false));
  }, [searchTerm, reportedType, reporterType, page, pageSize]);

  const deleteUserHandler = async () => {
    // await deleteUserReport({ listId });
    console.log("userId", userId);
    const response = await deleteUser({ userId });
    if (response) {
      const newPostList = allReports.filter((el) => el?._id !== listId);
      setAllReports(newPostList);
      makeToast("Post deleted successfully", "success", 3);
    } else {
      makeToast("Post deleted Not Deleted", "warn", 3);
    }
  };
  const tableHead = [
    "Label",
    "User Image",
    "Heading",
    "Description",
    "Username",
    "User Email",
    <FilterDropDown
      options={optionsReported}
      value={reportedType}
      setValue={setReportedType}
    />,
    "User Rating",
    "Reporter Image",
    "Reporter Name",
    "Reporter Email",
    <FilterDropDown
      options={optionsReporters}
      value={reporterType}
      setValue={setReporterType}
    />,
    "Delete User",
  ];
  if (loading) {
    return (
      <>
        <Loading loading={loading} />
      </>
    );
  }
  return (
    <>
      <ToastContainer />
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {/* sx={{ maxHeight: 440 }} */}
        <TableContainer>
          <Box
            p={1}
            sx={{
              display: "flex",
            }}
          >
            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onInput={handleSearch}
              sx={{ width: "100%" }}
            />
          </Box>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableHead.map((_, idx) => (
                  <TableCell key={idx}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 600,
                      }}
                    >
                      {_}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableSkeleton ? (
                <TableSkeleton
                  rowsLength={allReports?.length}
                  circular={2}
                  count={13}
                />
              ) : allReports.length > 0 ? (
                allReports.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row?._id}>
                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            {row.label}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell align="left">
                        <Box
                          sx={{
                            borderRadius: "50%",
                            overflow: "hidden",
                            width: "50px",
                            height: "50px",
                          }}
                          onClick={() => handlePhotoClick(index)}
                        >
                          <img
                            src={
                              row?.reportedUser?.profilePic
                                ? row?.reportedUser?.profilePic
                                : person
                            }
                            width="50px"
                            height="50px"
                            style={{
                              //   borderRadius: "50%",
                              scale: "1.1",
                              objectFit: "cover",
                              border: "1px solid gray",
                              width: "50px",
                            }}
                            alt={"profile"}
                          />
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            {row?.heading}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            {row?.description}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "500",
                              color: row?.reportedUser?.firstName ? "" : "red",
                            }}
                          >
                            {row?.reportedUser?.firstName +
                              " " +
                              row?.reportedUser?.lastName || "No Text"}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "500",
                              color: row?.reportedUser?.email ? "" : "red",
                            }}
                          >
                            {row?.reportedUser?.email || "No Email"}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "500",
                              color: row?.reportedUser?.accountType
                                ? ""
                                : "red",
                            }}
                          >
                            {row?.reportedUser?.accountType || "Type Not Found"}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "500",
                              color: row?.reportedUser?.ratingStartValue
                                ? ""
                                : "red",
                            }}
                          >
                            {row?.reportedUser?.ratingStartValue ||
                              "Not Available"}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            borderRadius: "50%",
                            overflow: "hidden",
                            width: "50px",
                            height: "50px",
                          }}
                          onClick={() => handlePhotoClick(index)}
                        >
                          <img
                            src={
                              row?.reporterId?.profilePic
                                ? row?.reporterId?.profilePic
                                : person
                            }
                            width="50px"
                            height="50px"
                            style={{
                              //   borderRadius: "50%",
                              scale: "1.1",
                              objectFit: "cover",
                              border: "1px solid gray",
                              width: "50px",
                            }}
                            alt={"Profile"}
                          />
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "500",
                              color: row?.reporterId?.firstName ? "" : "red",
                            }}
                          >
                            {row?.reporterId?.firstName +
                              row?.reporterId?.lastName || "Not Available"}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {tableText(row?.reporterId?.email)}
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {tableText(row?.reporterId?.accountType)}
                        </Box>
                      </TableCell>

                      <TableCell align="left">
                        <button
                          style={{
                            padding: "5px",
                            minWidth: "90px",
                            border: "none",
                            outline: "none",
                            borderRadius: "4px",
                            backgroundColor: "#F24C3D",
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleDelete(row?.reportedUser?._id, row?._id)
                          }
                        >
                          Delete User
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={13} align="center">
                    <Typography
                      variant="h6"
                      component="h6"
                      align="center"
                      style={{ color: "red" }}
                    >
                      No Data Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={totalCounts}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(e) => {
            setPageSize(parseInt(e.target.value, 10));
            setPage(0);
          }}
        />
      </Paper>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0",
          margin: "0",
          height: "100vh",
        }}
      >
        <div
          className="slider-container"
          style={{
            maxWidth: "900px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Slider {...settings} style={{ width: "100%", height: "100%" }}>
            {Array.isArray(allReports[selectedPhotoIndex]) &&
              allReports[selectedPhotoIndex].map((row, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src={
                      row?.reportedUser?.profilePic
                        ? row?.reportedUser?.profilePic
                        : person
                    }
                    alt={`Photo ${index}`}
                    style={{
                      maxWidth: "900px",
                      minHeight: "900px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              ))}
          </Slider>
        </div>
      </Modal>
      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        title={"Delete Reported Post"}
        functionToPerform={deleteUserHandler}
      />
    </>
  );
}

export default UserReportPage;
