import { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchNewAccessToken } from "../api/auth";
import { setUserData } from "../redux/slices/userSlice";
import { setLogin } from "../redux/slices/authSlice";

const UsePersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        const response = await fetchNewAccessToken();
        if (response?.data?.success === true) {
          console.log("response", response?.data);
          localStorage.setItem("accessToken", response?.data?.accessToken);
          dispatch(setUserData(response?.data?.user));
          dispatch(setLogin(response?.data?.accessToken));
        }
      } catch (err) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    if (!isAuthenticated) {
      verifyRefreshToken();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isError) {
    return <Navigate to="/login" />;
  }
  return <>{isLoading ? <div>Loading...</div> : <Outlet />}</>;
};

export default UsePersistLogin;
